import { observable, action, reaction } from 'mobx';
import validator from '../../../validator';
import agencyStore from './AgencyStore';
import {
	IissueFields,
	IformState,
	IsourceMultiSelect,
	Ioptions,
	Iissue,
	IFeedlyBoard,
	IkeyWords,
} from '../interface/issue';
import CatalogServices from '../../../services/CatalogServices';
import { literals } from '../../../validator/literals';

const issue = {
	title: '',
	filters: {
		details: {
			creators: '',
		},
		keywords: [],
	},
	_id: '',
	documents: [],
};

const issueFields = {
	title: '',
	keyWord: '',
};

const formState = {
	errorFields: {
		title: '',
		keyWord: '',
		feedly: '',
		source: '',
	},
	overlayId: 'overlay',
	sources: [],
	isDisableFormBtn: true,
	isShowDocInfo: false,
	feedly: false,
	feedlyBoards: [],
	isDisableFeedlySwitcher: false,
};

const defaultMultiSelect = {
	options: [],
	selectedOptions: [],
};
class AgencyIssueFormStore {
	@observable issue: Iissue = { ...issue };

	@observable issueFields: IissueFields = { ...issueFields };

	@observable formState: IformState = { ...formState };

	@observable sourceMultiSelect: IsourceMultiSelect = { ...defaultMultiSelect };

	@observable isEditOverlay: boolean = false;

	@observable feedlyBoardMultiSelect: IsourceMultiSelect = {
		...defaultMultiSelect,
	};

	@action getAllSources = async () => {
		const { data } = await CatalogServices.getAllSources();
		this.sourceMultiSelect.options = this.formatSourceForMultiSelect(data);
	};

	private formatSourceForMultiSelect = (
		data: Array<{ _id: string; title: string }>
	) => {
		const result: Array<Ioptions> = [];
		data.forEach(item => {
			if (item.title === 'Feedly') return;
			result.push({ value: item._id, label: item.title });
		});
		return result;
	};

	@action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		const error = validator.validateField(name, value);

		this.setError(name, error);
		this.issueFields[name] = value;
	};

	private setError = (
		name: string,
		error: { isFieldValid: boolean; errorMessage: string }
	) => {
		if (!error.isFieldValid)
			return (this.formState.errorFields[name] = error.errorMessage);
		else return (this.formState.errorFields[name] = '');
	};

	@action onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const errorTitle = validator.validateField('title', this.issueFields.title);

		if (!errorTitle.isFieldValid) {
			this.setError('title', errorTitle);
			return;
		}

		const keywords = this.formatKeyWordsForSend(this.issue.filters.keywords);
		const issue = {
			...this.issue,
			filters: {
				keywords,
			},
			title: this.issueFields.title,
		};

		if (this.isEditOverlay) {
			await agencyStore.editIssue(issue, this.issue._id);
		} else {
			await agencyStore.createNewIssue(issue);
		}

		agencyStore.removeOverlay(this.formState.overlayId);
	};

	private formatKeyWordsForSend = (keywords: Array<IkeyWords>) => {
		return keywords.map(keyword => {
			if (keyword.feedlyTags) {
				return { ...keyword, title: '', source: ['feedly'] };
			}
			return keyword;
		});
	};

	@action handleSourcesSelect = (value: any) => {
		const sources = value.map(
			(item: { value: string; label: string }) => item.value
		);

		this.sourceMultiSelect.selectedOptions = [...value];
		if (this.sourceMultiSelect.selectedOptions) {
			this.formState.errorFields.source = '';
		}
		this.formState.sources = sources;
	};

	@action addKeyWord = () => {
		const { issueFields, sourceMultiSelect } = this;

		const errorKeyWord = validator.validateField(
			'keyWord',
			issueFields.keyWord
		);

		if (errorKeyWord.isFieldValid && sourceMultiSelect.selectedOptions.length) {
			this.issue.filters.keywords = [
				...this.issue.filters.keywords,
				...[
					{
						title: this.issueFields.keyWord,
						source: this.formState.sources,
						_id: `${Math.floor(Math.random() * 100)}`,
					},
				],
			];

			this.issueFields.keyWord = '';
			this.sourceMultiSelect.selectedOptions = [];
		} else {
			if (!errorKeyWord.isFieldValid)
				this.formState.errorFields.keyWord = errorKeyWord.errorMessage;

			if (!sourceMultiSelect.selectedOptions.length)
				this.formState.errorFields.source = literals.source.empty;
		}
	};

	@action addFeedly = () => {
		if (!this.formState.feedlyBoards.length) {
			this.formState.errorFields.feedly = literals.feedly.empty;
			return;
		}

		const source = this.feedlyBoardMultiSelect.selectedOptions.map(option => {
			return option.label;
		});

		const feedly = [
			{
				title: 'Feedly',
				feedlyTags: this.formState.feedlyBoards,
				source,
				_id: `${Math.floor(Math.random() * 100)}`,
			},
		];

		this.issue.filters.keywords = [...this.issue.filters.keywords, ...feedly];

		this.formState.feedlyBoards = [];
		this.feedlyBoardMultiSelect.selectedOptions = [];
	};

	@action deleteKeyWord = (id: string) => {
		this.issue.filters.keywords = this.issue.filters.keywords.filter(
			keyWord => {
				return id !== keyWord._id;
			}
		);
	};

	@action resetStore = () => {
		this.issue = { ...issue };
		this.issueFields = { ...issueFields };
		this.formState = { ...formState };
		this.sourceMultiSelect = { ...defaultMultiSelect };
		this.feedlyBoardMultiSelect = { ...defaultMultiSelect };
		this.formState.feedly = false;
	};

	@action setIssue = (issue: Iissue) => {
		this.issue = { ...issue };
		this.issueFields.title = this.issue.title;
	};

	@action setIsEditOverlay = (isEditOverlay: boolean) => {
		this.isEditOverlay = isEditOverlay;
	};

	@action setFeedlyBoard = () => {
		this.feedlyBoardMultiSelect.options = agencyStore.feedlyBoard.map(
			(item: IFeedlyBoard) => {
				return { value: item._id, label: item.label };
			}
		);
	};

	@action toggleFeedly = async () => {
		this.formState.feedly = !this.formState.feedly;
	};

	@action handleFeedlySelect = (value: any) => {
		const feedlyBoards = value.map(
			(item: { value: string; label: string }) => item.value
		);
		this.feedlyBoardMultiSelect.selectedOptions = [...value];
		if (this.feedlyBoardMultiSelect.selectedOptions) {
			this.formState.errorFields.feedly = '';
		}
		this.formState.feedlyBoards = feedlyBoards;
	};
}

const agencyIssueFormStore = new AgencyIssueFormStore();
reaction(
	() => agencyIssueFormStore.issue.filters.keywords,
	() => {
		agencyIssueFormStore.formState.isDisableFormBtn = !agencyIssueFormStore
			.issue.filters.keywords.length;
	}
);
export default agencyIssueFormStore;
