import React from 'react';

interface InputProps {
	name: string;
	type: string;
	value: string;
	placeholder: string;
	className?: string;
	errorOnField?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isPasswordShow: boolean;
	toggleTypePassword: () => void;
	autocomplete?: string;
}

const ivalidFieldClass = 'invalid';

const InputPassword: React.FC<InputProps> = ({
	name,
	type,
	value,
	placeholder,
	onChange,
	className = 'input_password',
	errorOnField,
	onFocus = () => {},
	onBlur = () => {},
	autocomplete = '',
	isPasswordShow = false,
	toggleTypePassword,
}) => {
	const errorClass = !errorOnField ? '' : ivalidFieldClass;
	className = `${className} ${errorClass}`;

	return (
		<div className={className}>
			<div className="defaultInput_container">
				<input
					name={name}
					type={type}
					value={value}
					className={className}
					placeholder={placeholder}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					autoComplete={autocomplete}
				/>
				{errorOnField ? (
					<span className="errorMessage">{errorOnField}</span>
				) : null}
			</div>
			{value ?
				(isPasswordShow ? (
					<i className="fas fa-eye-slash" onClick={toggleTypePassword} />
					) : (
					<i className="fas fa-eye" onClick={toggleTypePassword} />
					)) 
				: null
			}
		</div>
	);
};

export default InputPassword;
