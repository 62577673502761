import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import IssuesList from '../Client/components/IssuesList';
import { observer } from 'mobx-react';
import AffairsList from '../Client/components/AffairsList';
import Button from '../../shared/Button';
import { clientStore } from '../Client/store';
import userStore from '../../stores/UserStore';
import { isMobileDevice } from 'react-select/lib/utils';
import AffairPage from '../../pages/Affair/index';
import MultiFilterBar from '../Client/components/MultiFilter/MultiFilterBar';
import MultiFilterOverlay from '../Client/components/MultiFilter/MultiFilterOverlay';
import { Bar } from 'react-chartjs-2';
import AccordionIssue from '../Agency/components/AccordionIssue';
import { agencyStore } from '../Agency/store';
import UserOverlay from '../Agency/overlays/UserOverlay';

@observer
export default class ClientPage extends Component {
  state = {
    user: JSON.parse(localStorage.getItem('USER') || '{}')
  }
  async componentDidMount() {
    agencyStore.getClientUsers(this.state.user._id);
  }
	openUserOverlay = () => {
		agencyStore.showOverlay(
			'overlay',
			<UserOverlay id="overlay" isEditOverlay={false} />
		);
	};


  render() {
    return (
      <div className='content user-view'>
        <br />
        <Button
          text="User anlegen"
          onClick={this.openUserOverlay}
          className="btn btn-outline-success mr-3"
        />
        <br />
        <br />
        <AccordionIssue display={'users'} />
      </div>
    );
  }
}
