import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import { agencyStore, agencyIssueFormStore } from '../store/index';
import KeyWord from '../../../shared/KeyWord';
import { Iissue } from '../interface/issue';
import Switch from 'react-switch';
import CustomSelect from '../../../shared/CustomSelect';

type IissueOverlayProps = {
	id: string;
	isEditOverlay: boolean;
	issue: Iissue;
};

@observer
class IssueOverlay extends Component<IissueOverlayProps> {
	async componentDidMount() {
		const { issue, isEditOverlay } = this.props;
		agencyIssueFormStore.setIsEditOverlay(isEditOverlay);
		agencyIssueFormStore.getAllSources();
		await agencyIssueFormStore.setFeedlyBoard();

		if (this.props.isEditOverlay) {
			agencyIssueFormStore.setIssue(issue);
		}
	}

	componentWillUnmount() {
		agencyIssueFormStore.resetStore();
	}

	render() {
		const { errorFields, isDisableFormBtn } = agencyIssueFormStore.formState;
		const {
			onChange,
			onFormSubmit,
			handleSourcesSelect,
			issueFields,
			addKeyWord,
			deleteKeyWord,
			issue,
			formState,
			toggleFeedly,
			sourceMultiSelect,
			feedlyBoardMultiSelect,
			handleFeedlySelect,
			addFeedly,
		} = agencyIssueFormStore;
		const titleOverlay = this.props.isEditOverlay
			? 'Issue bearbeiten'
			: 'Issue anlegen';
		return (
			<div className="issueOverlay">
				<button
					className="closeOverlay_btn"
					onClick={() => agencyStore.removeOverlay(this.props.id)}
				>
					Abbrechen
				</button>
				<h3>{titleOverlay}</h3>
				<form className="createClientOverlay_form" onSubmit={onFormSubmit}>
					<Input
						name="title"
						type="text"
						value={issueFields.title}
						placeholder="Titel"
						errorOnField={errorFields.title}
						onChange={onChange}
					/>
					<div>
						<div className="keyWord_container">
							{!formState.feedly ? (
								<div>
									<Input
										name="keyWord"
										type="text"
										value={issueFields.keyWord}
										placeholder="Schlüsselwort"
										errorOnField={errorFields.keyWord}
										onChange={onChange}
									/>
									<CustomSelect
										value={sourceMultiSelect.selectedOptions}
										onChange={handleSourcesSelect}
										className="react-select-container"
										classNamePrefix="react-select"
										placeholder="Quelle für Schlüsselwort auswählen..."
										options={sourceMultiSelect.options}
										errorOnField={errorFields.source}
										isMulti={true}
									/>
									<div className="keyWord_container__button">
										<Button
											onClick={addKeyWord}
											text="Schlüsselwort hinzufügen"
											className="btn btn-outline-success"
										/>
									</div>
								</div>
							) : (
								<div>
									<CustomSelect
										value={feedlyBoardMultiSelect.selectedOptions}
										onChange={handleFeedlySelect}
										classNamePrefix="react-select"
										className="react-select-container feedlyBoard"
										placeholder="Feedly Board"
										options={feedlyBoardMultiSelect.options}
										isMulti={true}
										errorOnField={errorFields.feedly}
									/>
									<div className="keyWord_container__button">
										<Button
											onClick={addFeedly}
											text="Schlüsselwort hinzufügen"
											className="btn btn-outline-success"
										/>
									</div>
								</div>
							)}
							{/* <div className="switch_feedly">
								<Switch
									onChange={toggleFeedly}
									checked={formState.feedly}
									name="feedly"
									disabled={formState.isDisableFeedlySwitcher}
								/>
								<label htmlFor="feedly">Feedly</label>
							</div> */}
						</div>
						{issue.filters.keywords.length ? (
							<div className="keyWords">
								{issue.filters.keywords.map((keyWord, key) => (
									<KeyWord
										keyWord={keyWord}
										key={key}
										isShowMode={false}
										deleteKeyWord={deleteKeyWord}
									/>
								))}
							</div>
						) : null}
					</div>
					<div className="issueOverlay_form__btn-group">
						<Button
							text={titleOverlay}
							className="btn btn-outline-success"
							type="submit"
							disabled={isDisableFormBtn}
						/>
						<Button
							text="Abbrechen"
							onClick={() => agencyStore.removeOverlay(this.props.id)}
							className="btn btn-outline-danger"
						/>
					</div>
				</form>
			</div>
		);
	}
}

export default IssueOverlay;
