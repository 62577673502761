import React, { Component } from 'react';
import KeyWord from '../../../shared/KeyWord';
import { agencyStore, agencyClientFormStore } from '../store';
import UserOverlay from '../overlays/UserOverlay';
import { DeleteOverlay } from '../overlays/DeleteOverlay';
import DocumentsList from './DocumentsList';
import { observer } from 'mobx-react';
import UserWatchIssue from './UserWatchIssue';

interface ICardProps {
    index: number;
    issueId: string;
}

@observer
class UserCards extends Component<ICardProps> {

    render() {
        const user = agencyStore.currentClientUsers[this.props.index];
        return (
            <div className="card">
                <div className="card-header d-flex" id={`heading${user._id}`}>
                    <div className='button-wrapper'>
                        {user.email}
                    </div>
                    <div className="btn-group">
                        <UserWatchIssue
                            user={user}
                        />
                    </div>
                    <div className='card-permissionLevel'>{user.permissionLevel === 'admin' ? "Admin" : "Benutzer"}</div>
                    <div className="btn-group">
                        <i
                            className="far fa-edit"
                            onClick={() => {
                                agencyClientFormStore.setUserInfo(user);
                                agencyStore.showOverlay(
                                    'overlay',
                                    <UserOverlay
                                        id="overlay"
                                        isEditOverlay={true}
                                    />
                                )
                            }
                            }
                        />
                        <i
                            className="fas fa-trash"
                            onClick={() =>
                                agencyStore.showOverlay(
                                    'overlay',
                                    <DeleteOverlay id={'overlay'} issueId={user._id} display={'user'} />
                                )
                            }
                        />
                    </div>
                </div>

                <div
                    id={`collapse${user._id}`}
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                >
                    <div className="card-body d-flex">
                        {/* {user.filters.keywords.map((keyWord, key) => (
                            <KeyWord keyWord={keyWord} key={key} />
                        ))} */}
                    </div>
                    {/* {user.documents && user.documents.length ? (
                        <ol>
                            <DocumentsList documents={user.documents} />
                        </ol>
                    ) : null} */}
                </div>
            </div>
        );
    }
};

export default UserCards;
