export enum USER_ROLES {
	CLIENT = 'client',
	AGENCY = 'agencyAdmin',
}
export interface User {
	role: string;
	password: string;
	username: string;
	permissionLevel: string;
	_id: string;
	issue: Array<string>;
	email: string;
	subEmail: string;
	agency: string;
	user_id: string;
}
