import React, { Component } from 'react';
import defaultLogo from '../assets/img/logo.png';
import mindsetLogo from '../assets/img/mindset.png';
import { url } from '../services/http/config';
import userStore from '../stores/UserStore';
import { observer } from 'mobx-react';
import Login from '../pages/Login';
import { USER_ROLES } from '../types/user/user';
import Agency from '../pages/Agency/index';
import ClientPage from '../pages/Client';
import { develop } from '../services/http/config';
import { Link, Redirect } from 'react-router-dom';
import { isMobileDevice } from 'react-select/lib/utils';

@observer
class Header extends Component {
	render() {
		const { user, showMenu, logout } = userStore;
		const getUserComponent = (user: any): any => {
			if (!user) return Login;
			return user.role === USER_ROLES.AGENCY ? Agency : ClientPage;
		}
		const headerActions = [
			{ label: 'Issue Feed', icon: 'fas fa-table', link: '/' },
			{ label: 'Reports', icon: 'fas fa-chart-pie', link: '/dashboard' },
			{ label: 'Benutzer', icon: 'fas fa-users', link: '/users', hidden: user && user.permissionLevel !== 'admin' }
		]
		const segments = window.location.href.split('/');
		const activePage = segments[3] ? '/' + segments[3] : '/';

		return (
			<header>
				<div className={`${getUserComponent(userStore.user) === Agency ? 'agency ' : ''}container header_container`}>
					<span className="button_menu" onClick={showMenu}>
						<i className="fas fa-bars"></i>
					</span>
					<div className="logoBox">
						{user ? (
							<img
								src={`${url}/v1/rest/user/${user._id}/avatar?auth_token=${userStore.token
									}`}
								alt="LOGO"
								className="logo"
								id="logo"
								onError={() => {
									const logo: any = document.getElementById('logo');
									if (logo) {
										logo.src = defaultLogo;
									}
								}}
							/>
						) : (
							<img src={!develop.production ? mindsetLogo : defaultLogo} alt="LOGO" className="logo" id="logo" />
						)}
					</div>
					{isMobileDevice() ? null : userStore.user && userStore.user != null ? (
						<ul className="headerActions list-unstyled">
							{headerActions.map((data, i) => {
								if (data.hidden) return <></>
								return (
									<li key={i}>
										<a href={data.link} className={`${activePage === data.link ? 'active' : ''}`}>
											<i className={data.icon}></i>
											<div>{data.label}</div>
										</a>
									</li>
								)
							})}
							<li>
								<a onClick={logout}>
									<i className="fas fa-sign-out-alt"></i>
									<div>Abmelden</div>
								</a>
							</li>
						</ul>
					) : null}
				</div>
			</header>
		);
	}
};

export default Header;
