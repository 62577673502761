import React from 'react';
import Helper from '../helper';

import userStore from '../stores/UserStore';


export const Loader = () => {
const { logout } = userStore;
	const logoutAndRemoveOverlay = () => {
		Helper.removeOverlay('overlay')
		logout()
	}
	return (
		<div className="loader" key={`loader-0`}>
			<div className="logout">
				<a onClick={logoutAndRemoveOverlay}>
					<i className="fas fa-sign-out-alt"></i>
					<div>Abmelden</div>
				</a>
			</div>
			<div className="l_main">
				<div className="l_square">
					<span />
					<span />
					<span />
				</div>
				<div className="l_square">
					<span />
					<span />
					<span />
				</div>
				<div className="l_square">
					<span />
					<span />
					<span />
				</div>
				<div className="l_square">
					<span />
					<span />
					<span />
				</div>
			</div>
		</div>
	);
}
