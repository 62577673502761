import React from 'react';
import { clientStore } from '../../Client/store';
import { observer } from 'mobx-react';
import userStore from '../../../stores/UserStore';
import { url } from '../../../services/http/config';
import Helper from '../../../helper/index';
import CardDocList from '../../Client/components/CardDocList/CardDocList';
import WatchIssue from './WatchIssue';
const PositiveSmiley = require('../../../assets/icons/PositiveSmiley.png')
const NeutralSmiley = require('../../../assets/icons/NeutralSmiley.png')
const NegativeSmiley = require('../../../assets/icons/NegativeSmiley.png')

interface PropsType {
	affairId: string;
}

const MediaAffair: React.FC<PropsType> = observer(() => {
	const { currentAffair } = clientStore;
	const mediaLink = `${url}${currentAffair.link}?auth_token=${userStore.token
		}`;
	let rating
	switch (currentAffair.parsedDetails.bewertung) {
		case "positiv":
			rating = PositiveSmiley
			break;
		case "neutral":
			rating = NeutralSmiley
			break;
		case "negativ":
			rating = NegativeSmiley
			break;
		default:
			break;
	}
	if (!currentAffair) return null;
	const date = new Date(currentAffair.lastUpdateDate).toLocaleDateString();
	return (
		<div className={`affair_item affairDetails media-affair`}>
			<div className="affair_block">
				<a href={currentAffair.source === 'Argus' ? currentAffair.link : mediaLink}
					target="_blank"
					rel="noopener noreferrer">
					<h3>
						{currentAffair.parsedDetails.title}
					</h3>
				</a>
				<WatchIssue />
			</div>
			<div className="affairDetails_description">
				<ul className="list-group affair_description" style={{ width: '100%' }}>
					{currentAffair.source !== 'Argus' && currentAffair.parsedDetails.businessNumber &&
						<li className="list-group-item">
							<p className="bold">Nr:</p>
							<p>{currentAffair.parsedDetails.businessNumber}</p>
						</li>}
					{currentAffair.lastUpdateDate &&
						<li className="list-group-item">
							<p>{date}</p>
							{/* <p className="update-date">{new Date(date).toLocaleDateString()}</p> */}
						</li>}
					{currentAffair.lead &&
						<li className="list-group-item" style={{ whiteSpace: 'pre-wrap', width: '100%' }}>
							<p style={{ width: '100%' }}>{currentAffair.lead}</p>
						</li>}
					{currentAffair.status &&
						<li className="list-group-item">
							<p className="bold">Stand:</p>
							<p>{currentAffair.status}</p>
						</li>}
					<li className="list-group-item">
					</li>
				</ul>
			</div>
			<div style={{ textAlign: 'center' }}><a target='_blank' href={currentAffair.source === 'Argus' ? currentAffair.link : mediaLink}>Link zum Artikel</a></div>

			<CardDocList data={currentAffair.details} cardType='media' listType='affair' link={mediaLink} date={date} />
			<br></br>
			<br></br>
			{currentAffair.source === 'Argus' && <table className="argusTable">
				<tbody>
					<tr>
						<td className="argusTableRow"><strong><div>{rating ? <img src={rating} className="argusRating" /> : currentAffair.parsedDetails && currentAffair.parsedDetails.bewertung}</div></strong></td>
						<td className="argusTableRow"><strong>{new Number(currentAffair.parsedDetails.reichweite).toLocaleString('de-CH')}</strong></td>
						<td className="argusTableRow"><strong>{new Number(currentAffair.parsedDetails.auflage).toLocaleString('de-CH')}</strong></td>
						<td className="argusTableRow"><strong>{new Number(currentAffair.parsedDetails.AEV).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}</strong></td>
						{/* <td className="argusTableRow"><strong>{currentAffair.date && new Date(currentAffair.date).toLocaleDateString()}</strong></td> */}
						{/* <td className="argusTableRow"><strong>{currentAffair.parsedDetails.language ? currentAffair.parsedDetails.language : 'N/A'}</strong></td> */}
					</tr>
					<tr>
						<td className="argusTableRow">Bewertung</td>
						<td className="argusTableRow">Nettoreichweite</td>
						<td className="argusTableRow">Auflage</td>
						<td className="argusTableRow">AEV Total</td>
						{/* <td className="argusTableRow">Lieferdatum</td> */}
						{/* <td className="argusTableRow">Sprache</td> */}
					</tr>

				</tbody>
			</table>}
		</div>
	);
});

export default MediaAffair;