import React, { Component } from 'react';
import IssuesList from '../Client/components/IssuesList';
import { observer } from 'mobx-react';
import { clientStore } from '../Client/store';
import userStore from '../../stores/UserStore';
import { isMobileDevice } from 'react-select/lib/utils';
import { Bar } from 'react-chartjs-2';

@observer
export default class ClientPage extends Component {
    state = {
        facetField: "source_txt",
        user: JSON.parse(localStorage.getItem('USER') || '{}')
    }
    async componentDidMount() {
        const facetField = this.state.facetField
        const sideBar: HTMLElement | null = document.querySelector('.issues-list');
        if (sideBar && isMobileDevice()) sideBar.style.transform = `translateX(-${userStore.getStartElementPosition('.issues-list')}px)`;
        userStore.isMobile = false;
        await clientStore.getSources();
        await clientStore.getUserIssues(true, facetField);
    }

    changeBreakdown = (e: any) => {
        this.setState({
            facetField: e.target.value
        })
        clientStore.getUserIssues(true, e.target.value);
    }

    render() {
        const { isHidden, translateStartSideBar, isMobile, showMenu } = userStore;
        return (
            <div className="content">
                <div className="overlay" style={{ display: !isHidden ? 'block' : 'none' }} onClick={showMenu}></div>
                <section
                    className="issues-list"
                    style={{
                        transform: isHidden ? translateStartSideBar : 'translateX(0)',
                        transition: isMobile ? 'transform 1s' : 'transform 0s'
                    }}
                >
                    <IssuesList dashboardFlag={true} facetField={this.state.facetField} />
                </section>
                <section className="dashboard-container">
                    {/* {clientStore.currentView === "affairs" && <MultiFilterBar dashboardFlag={true} />} */}
                    <div className="general-list">
                        {/* {clientStore.isOpenMultiFilter ? <MultiFilterOverlay dashboardFlag={true} facetField={this.state.facetField} /> : null} */}
                        <select
                            value={this.state.facetField}
                            onChange={this.changeBreakdown}
                            className='breakdown-selector'
                        >
                            <option value='source_txt'>Quelle</option>
                            <option value={`priority_${this.state.user._id}`}>Relevanz</option>
                            {/* <option value='isOpen_t_sort'>Open</option>
                            <option value='parsedDetails_creator_txt'>Author</option> */}
                        </select>
                        {clientStore.facet_counts && <Bar
                            /*
                            // @ts-ignore */
                            type='bar'
                            data={clientStore.facet_counts}
                            /*
                           // @ts-ignore */
                            options={{
                                // legend: {
                                //     position: 'bottom',
                                // },
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true
                                    }
                                },
                                maintainAspectRatio: false
                            }}
                        />}
                    </div>
                </section>
            </div >
        );
    }
}
