import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import IssuesList from './components/IssuesList';
import { observer } from 'mobx-react';
import AffairsList from './components/AffairsList';
import Button from '../../shared/Button';
import { clientStore } from './store';
import userStore from '../../stores/UserStore';
import { isMobileDevice } from 'react-select/lib/utils';
import AffairPage from '../../pages/Affair/index';
import MultiFilterBar from './components/MultiFilter/MultiFilterBar';
import MultiFilterOverlay from './components/MultiFilter/MultiFilterOverlay';
import moment from 'moment';
import { MentionsInput, Mention } from 'react-mentions'
import { agencyStore } from '../Agency/store';
import { RouteComponentProps } from 'react-router';
// import './mention-style.scss';
// import Comments from './components/Comments';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";

import http from '../../services/http';
import ReactQuillWrapper from './ReactQuillWrapper';

type PathParamsType = {
	id: string;
};

type PropsType = RouteComponentProps<PathParamsType> & {};

@observer
export default class ClientPage extends Component<PropsType> {
	constructor(props: any) {
		super(props);
	}

	state = {
		user: JSON.parse(localStorage.getItem('USER') || '{}'),
		userSuggestions: [],
		review: '',
	}
	quill: any;

	async componentDidMount() {
		const sideBar: HTMLElement | null = document.querySelector('.issues-list');
		if (sideBar && isMobileDevice()) sideBar.style.transform = `translateX(-${userStore.getStartElementPosition('.issues-list')}px)`;
		userStore.isMobile = false;
		await clientStore.getSources();
		await clientStore.getUserIssues();
		agencyStore.getClientUsers(this.state.user._id);
		clientStore.getBucket();
	}

	componentDidUpdate(prevProps: PropsType, prevState: PropsType) {
		const { currentClientUsers } = agencyStore
		let suggestions = [{ id: 'email', value: '' }]
		if (this.state.userSuggestions.length < 1 && currentClientUsers && currentClientUsers.length) {
			currentClientUsers.map((item, i) => {
				if (i == 0) {
					suggestions = [{
						id: 'email',
						value: item.email
					}]
				} else {
					suggestions = [...suggestions, {
						id: 'email',
						value: item.email
					}]
				}
			})
			this.setState({ userSuggestions: suggestions })
		}
	}

	scrollToTop = () => {
		const el: any = document.querySelector('.general-list .affair-list');
		if (el) {
			el.scrollTo({ top: 0, behavior: 'smooth' })
		}
	}

	imageHandler(image: any, callback: Function) {
		const input: any = document.createElement('input');
		input.setAttribute('type', 'file');
		input.click();

		input.onchange = () => {
			const file = input.files[0];

			// file type is only image.
			if (/^image\//.test(file.type)) {
				var formData = new FormData();
				formData.append("image", file);

				http.post(`/v1/rest/upload-comments-doc`, formData).then((res: any) => {
					if (res.status === 200) {
						const range = this.quill.getSelection(true);
						this.quill.insertEmbed(range.index, 'image', res.data.link);
					} else {
						var reader = new FileReader();
						reader.onload = function (e: any) {
							callback(e.target.result);
						};
						reader.readAsDataURL(image);
					}
				}).catch(e => {
					console.error(e);
				});
			} else {
				console.warn('You could only upload images.');
			}
		};
	}

	async submitHandler(e: React.FormEvent<HTMLFormElement>) {
		await clientStore.commentFormHandler(e)
		var element = document.getElementsByClassName("ql-editor");
		element[0].innerHTML = "";
	}

	render() {
		const { isHidden, translateStartSideBar, isMobile, showMenu } = userStore;
		const atValues = this.state.userSuggestions;
		const hashValues: any = [];

		return (
			<div className="content">
				<div className="overlay" style={{ display: !isHidden ? 'block' : 'none' }} onClick={showMenu}></div>
				<section
					className="issues-list"
					style={{
						transform: isHidden ? translateStartSideBar : 'translateX(0)',
						transition: isMobile ? 'transform 1s' : 'transform 0s'
					}}
				>
					<IssuesList />
				</section>
				<section className="affair-list">
					{clientStore.currentView === "affairs"
						? <MultiFilterBar currentView={clientStore.currentView} />
						: <div style={{ height: '68px' }}></div>}
					<div className="general-list">
						{clientStore.isOpenMultiFilter ? <MultiFilterOverlay /> : null}
						<Route
							exact
							path="/"
							component={() => <AffairsList isAllAffairs={true} />}
						/>
					</div>

					<Button className={'back_to_top_button'} text={'ↆ'} onClick={this.scrollToTop} />
				</section>
				<div className="side-affair-card" style={(!clientStore.isMobileButtonExit && isMobileDevice()) ? { display: 'none' } : {}}>
					{clientStore.currentAffair._id && <div className='side-affair-wrapper'>
						<AffairPage affairId={clientStore.currentAffair._id} affairType={clientStore.currentAffair.docType} />
						<div>
							<Fragment>
								<div className='affair-comment-section'>
									<div className='affair-comment-input-wrapper'>
										<div className="block-titles">Beurteilung</div>
										{userStore.user && userStore.user.role === "agency-admin"
											? <Fragment>
												<textarea
													placeholder="eine Beurteilung schreiben ..."
													value={clientStore.review}
													onChange={(e) => clientStore.reviewChange(e)}
												></textarea>
												<div className="affair-comment-action">
													<button className='affair-comment-send'
														onClick={() => clientStore.submitReview()}
													>
														<i className="fas fa-paper-plane"></i> speichern
													</button>
												</div>
											</Fragment>
											: <p>{clientStore.currentAffair && clientStore.currentAffair.review}</p>}
									</div>
								</div>
							</Fragment>
							<hr></hr>
							<div className='affair-comment-divider'>
								{/* &#8226; &#8226; &#8226; */}
							</div>
							<div className='affair-comment-section'>
								<form onSubmit={this.submitHandler} className='affair-comment-input-wrapper'>
									<div className="block-titles" style={{ marginBottom: "0.5rem" }}>Kommentare</div>
									<ReactQuillWrapper
										clientStore={clientStore}
										ref={(el: any) => { this.quill = el; }}
										imageHandler={this.imageHandler}
										userSuggestions={this.state.userSuggestions}
									/>
									<div className="affair-comment-action">
										<button className='affair-comment-send'>
											<i className="fas fa-paper-plane"></i> speichern
										</button>
										<button
											type="button"
											id="clear-editor"
											style={{ display: 'none' }}
											onClick={() => {
												var element = document.getElementsByClassName("ql-editor");
												element[0].innerHTML = "";
											}} className='affair-comment-send clear-editor'>
											<i className="fas fa-times"></i> CLEAR
										</button>
									</div>
								</form>
								<div className='affair-comment-list'>
									{clientStore.currentAffair.comment ? clientStore.currentAffair.comment.map((item, i) => (
										item && <div key={i} className='affair-comment'>
											<span dangerouslySetInnerHTML={function () {
												return { __html: item.comment }
											}()}></span>
											<div className='affair-comment-list-label'>
												<span>{moment(item.creationDate).fromNow()}</span>
												<span>{item.email}</span>
											</div>
											<hr />
										</div>
									)).sort((a: any, b: any) => a && b && (a.creationDate > b.creationDate) ? 1 : -1) : null}
								</div>
							</div>
						</div>
					</div>}
				</div>
			</div >
		);
	}

}