import React, { ReactElement } from 'react';
import PolitAffair from './components/PolitAffair';
import MediaAffair from './components/MediaAffair';
import FeedlyAffair from './components/FeedlyAffair';

interface IRouterProps {
	affairId: any;
	affairType: any;
}

interface IAffairsComponents {
	[key: string]: ReactElement;
	political: ReactElement;
	media: ReactElement;
	[`feedly-doc`]: ReactElement;
}

class AffairPage extends React.Component<IRouterProps> {

	render() {
		const { affairType, affairId } = this.props;
		const affairs: IAffairsComponents = {
			political: <PolitAffair affairId={affairId} />,
			media: <MediaAffair affairId={affairId} />,
			[`feedly-doc`]: <FeedlyAffair affairId={affairId} />,
		};
		return affairs[affairType];
	}
}

export default AffairPage;
