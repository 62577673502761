import React from 'react';
import { clientStore } from '../../Client/store';
import { observer } from 'mobx-react';
import Helper from '../../../helper/index';
import CardDocList from '../../Client/components/CardDocList/CardDocList';
import filterDocs from '../../Client/components/CardDocList/filterDocs';
import sortByDocDate from '../../Client/components/CardDocList/sortByDocDate';
import WatchIssue from './WatchIssue';

interface PropsType {
	affairId: string;
}

const PolitAffair: React.FC<PropsType> = observer(() => {
	const { currentAffair } = clientStore;
	const sortedDocs = filterDocs(sortByDocDate(currentAffair.details.docs));
	const keyWords = Array.from(
		new Set(
			currentAffair.details.docs
				.filter((e: any) => e.keywords.length)
				.map((e: any) => e.keywords)
				.flat(Infinity)
		)
	);

	if (!currentAffair) return null;

	return (
		<div className={`affair_item affairDetails political-affair`}>
			<div className="affair_block">
				<a href={currentAffair.link}
					target="_blank"
					rel="noopener noreferrer">
					<h3>
						{currentAffair.parsedDetails.title}
					</h3>
				</a>
				<WatchIssue />
			</div>
			<div className="affairDetails_description">
				<ul className="list-group affair_description">
					{currentAffair.parsedDetails.businessNumber &&
						<li className="list-group-item">
							<p className="bold">Nr:</p>
							<p>{currentAffair.parsedDetails.businessNumber}</p>
						</li>}
					{currentAffair.lastUpdateDate &&
						<li className="list-group-item">
							<p className="bold">Letzte Aktualisierung:</p>
							<p className="update-date">{Helper.normalizeDate(currentAffair.lastUpdateDate)}</p>
						</li>}
					{currentAffair.status &&
						<li className="list-group-item">
							<p className="bold">Stand:</p>
							<p>{currentAffair.status}</p>
						</li>}
				</ul>
				{!sortedDocs.length && keyWords.length ? (
					<div className="polit-keywords">
						<p>Schlüsselwörter:</p>
						<div className='keyword-block'>
							{keyWords.map((keyWord: any, key: number) => (
								<span
									className="custom-keyword"
									key={`${keyWord}-${key}`}
								>
									{keyWord}
								</span>
							))}
						</div>
					</div>
				) : null}
			</div>
			<CardDocList
				data={currentAffair.details}
				cardType="polit"
				listType="affair"
			/>
		</div>
	);
});

export default PolitAffair;
