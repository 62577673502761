import React, { MouseEvent } from 'react';
import garbage from '../assets/icons/garbage.svg';
import refresh from '../assets/icons/refresh.svg';
import cancel from '../assets/icons/cancel.svg';

interface ButtonProps {
	className?: string;
	iconType?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
	className = 'card-icon',
	iconType = '',
	onClick = () => {},
}) => {
  const icons: { readonly [index: string]: string } = {
    garbage,
		refresh,
		cancel
  }
	return (
    <img src={icons[iconType]} alt="*" className={className} onClick={onClick}/>
	);
};

export default Button;
