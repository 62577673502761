import React from 'react';
import { agencyStore } from '../store';
import Button from '../../../shared/Button';

interface IDeleteOverlayProps {
	id: string;
	issueId: string;
	display: string;
}

export const DeleteOverlay: React.FC<IDeleteOverlayProps> = ({
	id,
	issueId,
	display,
}) => {
	const deleteIssue = async () => {
		if (display === 'issue') {
			await agencyStore.deleteIssue(issueId);
		}
		if (display === 'user') {
			await agencyStore.deleteSubUser(issueId);

		}
		agencyStore.removeOverlay(id);

	};

	return (
		<div className={'deleteOverlay'}>
			<button
				className="closeOverlay_btn"
				onClick={() => agencyStore.removeOverlay(id)}
			>
				Abbrechen
			</button>
			<div className={'deleteOverlay_trashImage'}>
				<i className="fas fa-trash-alt" />
			</div>
			<div className={'text_delete_overlay'}>
				{
					display === 'issue'
						? "Möchten Sie das Issue wirklich löschen?"
						: "Möchten Sie das User wirklich löschen?"
				}
			</div>
			<div className="deleteOverlay_form__btn-group">
				<Button
					text={'OK'}
					className="btn btn-outline-success"
					type="submit"
					onClick={deleteIssue}
				/>
				<Button
					text="Abbrechen"
					onClick={() => agencyStore.removeOverlay(id)}
					className="btn btn-outline-danger"
				/>
			</div>
		</div>
	);
};
