import React from 'react';
import clientStore from '../../store/ClientStore';

const MobileButtonExit = () => {
	return (
		<div className='mobile_button'>
			<div className='icon_exit' onClick={clientStore.closeAffair}>
				<i className="fas fa-times"></i>
			</div>
		</div>
	)
};

export default MobileButtonExit;
