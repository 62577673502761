import { observable, action } from 'mobx';
import validator from '../../../validator';
import agencyStore from './AgencyStore';
import { IerrorFields } from '../interface/clientInfo';
import { ICreateClient, Ilogo, ICreateUser } from '../interface/createClint';
import userStore from '../../../stores/UserStore';

const defaultLogo = {
	file: {},
	value: '',
};

const defaultUserInfo = {
	email: '',
	password: '',
	permissionLevel: 'user',
};

const defaultClientInfo = {
	username: '',
	newsFeed: false,
};

const defaultFormState = {
	errorFields: {
		username: '',
	},
	overlayId: 'overlay',
};

interface IformState {
	errorFields: IerrorFields;
	overlayId: string;
}
export interface Idata {
	email: string;
	password?: string | undefined;
	username: string;
}

class AgencyClientFormStore {
	@observable logo: Ilogo = { ...defaultLogo };

	@observable clientInfo: ICreateClient = { ...defaultClientInfo };

	@observable userInfo: ICreateUser = { ...defaultUserInfo };

	@observable formState: IformState = { ...defaultFormState };

	@observable isPasswordShow: boolean = false;

	@observable isEditOverlay: boolean = false;

	@action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { value, name } = event.target;

		const error = validator.validateField(name, value);

		if (event.target.type === 'file') {
			if (event.target.files) {
				this.logo = { value: value, file: event.target.files[0] };
				return;
			}
		}

		this.setError(name, error);
		this.clientInfo[name] = value;
	};

	@action onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { checked, name } = event.target;
		this.clientInfo[name] = checked;
	};

	@action onChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
		let { value, name } = event.target;

		const error = validator.validateField(name, value);

		if (event.target.type === 'file') {
			if (event.target.files) {
				this.logo = { value: value, file: event.target.files[0] };
				return;
			}
		}

		this.setError(name, error);
		this.userInfo[name] = value;
	};

	@action onChangeUserSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		let { value, name } = event.target;
		this.userInfo[name] = value;
	};

	private setError = (
		name: string,
		error: { isFieldValid: boolean; errorMessage: string }
	) => {
		if (!error.isFieldValid)
			return (this.formState.errorFields[name] = error.errorMessage);
		else return (this.formState.errorFields[name] = '');
	};

	private checkForm = (data: { [key: string]: any }) => {
		let isFormValid = true;
		for (let key in data) {
			if (key !== "newsFeed") {
				const error = validator.validateField(key, data[key]);
				isFormValid = this.setError(key, error) ? false : true;
			}
		}
		return isFormValid;
	};

	@action onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.isEditOverlay) {
			const { clientInfo } = this;
			const data: { username: string, newsFeed: boolean } = {
				username: clientInfo.username,
				newsFeed: clientInfo.newsFeed
			};
			const isFormValid = this.checkForm(data);
			if (!isFormValid) return;
			await agencyStore.editClient(data, this.logo);
		} else {
			const isFormValid = this.checkForm(this.clientInfo);
			if (!isFormValid) return;
			await agencyStore.addClient(this.clientInfo, this.logo);
		}
		const id = agencyStore.currentClient._id
			? agencyStore.currentClient._id
			: userStore.user && userStore.user._id
				? userStore.user._id
				: ''
		if (id) agencyStore.getCurrentClient(id);
		agencyStore.removeOverlay(this.formState.overlayId);
	};

	@action onFormSubmitUser = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (this.isEditOverlay) {
			const { userInfo } = this;
			const data: { [key: string]: string } = {
				_id: userInfo._id,
				email: userInfo.email,
				permissionLevel: userInfo.permissionLevel,
			};
			const isFormValid = this.checkForm(data);
			if (!isFormValid) return;
			await agencyStore.editClientUser(data, this.logo);
		} else {
			const isFormValid = this.checkForm(this.userInfo);
			if (!isFormValid) return;
			await agencyStore.addUser(this.userInfo, this.logo);
		}

		const id = agencyStore.currentClient._id
			? agencyStore.currentClient._id
			: userStore.user && userStore.user._id
				? userStore.user._id
				: ''
		if (id) agencyStore.getClientUsers(id);
		agencyStore.removeOverlay(this.formState.overlayId);
	};

	@action updateWatcherStatus = async (status: boolean, user:any) => {
		const data: any = {
			_id: user._id,
			isWatcher: status,
		};
		await agencyStore.editClientUser(data, this.logo);
	}

	@action setClientInfo = (clientInfo: any) => {
		this.clientInfo = { ...clientInfo, password: '' };
	};

	@action setUserInfo = (userInfo: any) => {
		this.userInfo = { ...userInfo }
	}

	@action toggleTypePassword = () => {
		this.isPasswordShow = !this.isPasswordShow;
	};

	@action resetStore = () => {
		this.logo = { ...defaultLogo };
		this.clientInfo = { ...defaultClientInfo };
		this.userInfo = { ...defaultUserInfo }
		this.formState = { ...defaultFormState };
		this.isPasswordShow = false;
	};

	@action setIsEditOverlay = (isEditOverlay: boolean) => {
		this.isEditOverlay = isEditOverlay;
	};
}

const agencyClientFormStore = new AgencyClientFormStore();
export default agencyClientFormStore;
