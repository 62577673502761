import React from 'react';
import Select from 'react-select';
import { toJS } from 'mobx';

interface IdefaultOptions {
	value: string;
	label: string;
}
interface InputProps {
	value: Array<IdefaultOptions>;
	options: Array<IdefaultOptions>;
	placeholder: string;
	className?: string;
	errorOnField?: string;
	isMulti: boolean;
	onChange: (e: any) => void;
	classNamePrefix?: string;
	name?:string;
}

const ivalidFieldClass = 'invalid';

const CustomSelect: React.FC<InputProps> = ({
	value,
	placeholder,
	onChange,
	options,
	className = 'defaultSelect',
	errorOnField,
	classNamePrefix = '',
	isMulti = true,
	name,
}) => {
	const errorClass = !errorOnField ? '' : ivalidFieldClass;
	className = `${className} ${errorClass}`;

	return (
		<div className="defaultSelect_container">
			<Select
				value={toJS(value)}
				name={name}
				onChange={onChange}
				placeholder={placeholder}
				options={toJS(options)}
				isMulti={isMulti}
				classNamePrefix={classNamePrefix}
				className={className}
			/>
			{errorOnField ? (
				<span className="errorMessage">{errorOnField}</span>
			) : null}
		</div>
	);
};

export default CustomSelect;
