import React from 'react';
import { observer } from 'mobx-react';
import { IaffairDetails } from '../../interface/affair';
import sortByDocDate from './sortByDocDate';
import filterDocs from './filterDocs';

interface IPropsType {
	data: IaffairDetails;
	link?: string | undefined;
	cardType: string;
	listType?: string;
	date?: string;
}

const CardDocList: React.FC<IPropsType> = observer(({ data, link, cardType, listType, date = '' }) => {
	const sortedDocs = cardType === 'polit' ?
		filterDocs(
			sortByDocDate(data.docs)
		)
		: data.docs;

	const setDocLink = (docLink: string): string => {
		switch (cardType) {
			case 'polit':
				return docLink;
			case 'feedly':
			case 'media':
				return link || ''
			default:
				return ''
		};
	}
	if (sortedDocs && !sortedDocs.length) return null;
	if (cardType === 'feedly' && !(link && link.match(/http/))) return null;
	return (
		<div className="docs">
			{cardType === 'polit' && <div>
				<div className="block-title">Dokumente</div>
				<table className="doc-info">
					<thead>
						<tr>
							<th>Datum</th>
							<th>Titel</th>
						</tr>
					</thead>
					<tbody>
						{sortedDocs.map((doc: any, key: number) => {
							const docLink = setDocLink(doc.link || doc.url);
							return (

								<tr key={`${doc.title}-${key}`}>
									<td>
										{doc.date || doc.from || date}
									</td>
									<td>
										<span className="" onClick={() => {
											window.open(docLink, '_blank')
										}}>
											{doc.title || doc.description || doc.designation}
										</span>
										{doc.keywords.length ? (

											<ul className="keyword-block">
												{doc.keywords.map((keyWord: any, key: number) => (
													<li className="custom-keyword" key={`${keyWord}-${key}`}>
														{keyWord}
													</li>
												))}
											</ul>

										) : null}
									</td>
								</tr>
							)
						})
						}
					</tbody>
				</table>
			</div>}
		</div>
	)
});

export default CardDocList;