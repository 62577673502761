import React from 'react';
import Cards from './Cards';
import UserCards from './UserCards'
import { agencyStore } from '../store';
import { observer } from 'mobx-react';

interface AccordianProps {
	display: string;
}

const AccordionIssue: React.FC<AccordianProps> = observer(({ display }) => {
	const { currentClientIssues, currentClientUsers } = agencyStore;
	return (
		<div id="accordion">
			{display === 'issues'
				? currentClientIssues.map((item, index) => (
					<Cards issueId={item._id} key={item._id} index={index} />
				))
				: currentClientUsers.map((item, index) => (
					<UserCards issueId={item._id} key={item._id} index={index} />
				))}
		</div>
	);
})

export default AccordionIssue;
