import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../shared/Button';
import { agencyStore } from '../store';
import { observer } from 'mobx-react';

type PropsType = {
	showCreateClientOverlay: () => void;
};

@observer
class ClientList extends React.Component<PropsType> {
	componentDidMount() {
		agencyStore.resetStore();
		agencyStore.getAllClients();
		agencyStore.getFeedlyBoard();
	}

	render() {
		const { clientsInfo } = agencyStore;
		const { showCreateClientOverlay } = this.props;
		return (
			<div className="sideBar_block">
				<div className="btn-group">
					<Button
						onClick={showCreateClientOverlay}
						text="Neuen Kunden anlegen"
						className="btn btn-outline-primary btn-sm"
					/>
				</div>
				<br></br>
				<br></br>
				<ul>
					{clientsInfo.map(({ username, _id }) => {
						const active = agencyStore.activeTab === _id ? 'active_tab' : '';
						return (
							<li key={_id}>
								<Link to={`/client/${_id}`} className={active}>
									{username}
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

export default ClientList;
