import { observable, reaction, action } from 'mobx';
import { storageService, STORAGE_CONST } from '../services/StorageService';
import { User } from '../types/user/user';
import { isMobileDevice } from 'react-select/lib/utils';

const getStartElementPosition = (selector: string): void => {
	const element: any = document.querySelector(selector);
	return element && element.clientWidth;
}

class UserStore {
	constructor() {
		this.user = storageService.getItem(STORAGE_CONST.USER);
		this.token = storageService.getItem(STORAGE_CONST.TOKEN);
	}
	@observable token: string | null = null;
	@observable user: User | null = null;
	@observable logo: string | undefined = undefined;
	@observable isHidden: boolean = true;
	@observable isMobile: boolean = false;
	@observable translateStartSideBar: string = '';
	@observable translateStartLoginContainer: string = "";

	@action
	logout = () => {
		this.user = null;
		this.token = null;
		this.isHidden = true;
	};

	@action
	showMenu = () => {
		this.isMobile = true;
		this.isHidden = !this.isHidden;
		this.translateStartSideBar = `translateX(-${this.getStartElementPosition('.issues-list')}px)`;
		this.translateStartLoginContainer = `translateX(-${this.getStartElementPosition('.login-container')}px)`;
	}

	@action toggleMenu = () => {
		this.isHidden = !this.isHidden;
	}
	@action checkIsMobileVersion = () => {
		this.isMobile = isMobileDevice();
		this.isHidden = isMobileDevice();
	}

	getStartElementPosition = getStartElementPosition;

}

const userStore = new UserStore();
reaction(
	() => userStore.user,
	user => {
		storageService.setItem(STORAGE_CONST.USER, user);
	}
);
reaction(
	() => userStore.token,
	token => storageService.setItem(STORAGE_CONST.TOKEN, token)
);

export default userStore;
