import axios from 'axios';
import { url } from './config';
import { ToastsStore } from 'react-toasts';

const http = axios.create({ baseURL: url });

 interface ILangMap {
	[string : string]: string;
 }

const langMap:ILangMap  = {
	'Error: User with such email already exists': 'Diese EMail-Adresse wird bereits bei einem bestehenden Kunden verwendet - bitte  geben Sie eine andere EMail-Adresse an.'
}

http.interceptors.request.use(request => {
	const TOKEN = localStorage.getItem('auth_token');
	if (!TOKEN) return request;

	request.params = {
		...request.params,
		auth_token: JSON.parse(TOKEN),
	};

	return request;
});

http.interceptors.response.use(
	response => {
		if (
			// response.config.method === 'post' ||
			response.config.method === 'put' ||
			response.config.method === 'delete'
		) {
			ToastsStore.success('Daten erfolgreich upgedatet');
		}
		return response;
	},
	error => {
		console.log(error.message);

		// TODO change it because it is GOVNO-CODE
		let message = (error.response
			&& error.response.hasOwnProperty('data')
			&& error.response.data.error
		) ? splitError(error.response.data.error) : error.message;
		
		if(langMap.hasOwnProperty(message)) {
			message = langMap[message];
		}
		
		console.log(message);

		ToastsStore.error(message);

		return Promise.reject(error);
	},
);


// TODO change it because it is GOVNO-CODE too
function splitError(message:string) {
	return message.split('\n')[0];
}

export default http;
