import React from 'react';
import { observer } from 'mobx-react';
import clientStore from '../../store/ClientStore';
import { IAffairCard } from '../../interface/affair';

interface IPropsType {
    affair: IAffairCard,
	type: string,
	index: number
}

@observer
class PrioritySwitcher extends React.Component<IPropsType> {
    render() {
        const {id, priority} = this.props.affair;
        const { type, index} = this.props;
        return (
            <div className='priority_block'>
                    <ul className="check-list">
                        {priority !== 'high' && <li className="high" onClick={clientStore.addPriority(type, id, 'high', index)}></li>}
                        {priority !== 'medium' && <li className="medium" onClick={clientStore.addPriority(type, id, 'medium', index)}></li>}
                        {priority !== 'low' && <li className="low" onClick={clientStore.addPriority(type, id, 'low', index)}></li>}
                        {priority !== null && <li className="none" onClick={clientStore.addPriority(type, id, null, index)}></li>}
                    </ul>
                    <div className={`feed-changer ${priority}`}>
                        <div className="switcher" id={id}></div>
                    </div>

            </div>
        )
    }
}
export default PrioritySwitcher;