import React from 'react';
import { IkeyWords } from '../pages/Agency/interface/issue';

interface IKeyWordProps {
	keyWord: IkeyWords;
	isShowMode?: boolean;
	deleteKeyWord?: (id: string) => void;
}

const KeyWord: React.FC<IKeyWordProps> = ({
	keyWord,
	isShowMode = true,
	deleteKeyWord = () => {},
}) => {
	return (
		<div className="card text-white bg-dark mb-3 card-controle">
			<div className="card-header">
				{keyWord.title}
				{!isShowMode ? (
					<i
						onClick={() => deleteKeyWord(keyWord._id)}
						className="fas fa-trash"
					/>
				) : null}
			</div>
			<div className="card-body">
				{keyWord.source.map((source, key) => (
					<div className="btn btn-primary btn-sm" key={key}>
						 {source}
					</div>
				))}
			</div>
		</div>
	);
};

export default KeyWord;
