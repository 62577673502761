import { observable, action } from 'mobx';
import userStore from '../../../stores/UserStore';
import CatalogServices from '../../../services/CatalogServices';

class LoginForm {
	@observable form: any = {
		email: '',
		password: '',
	};
	@observable authError: string = '';
	@observable isPasswordShow: boolean = false;

	@action login = async (event: React.FormEvent<HTMLFormElement>, searchParams: string) => {
		event.preventDefault();
		const { email, password } = this.form;
		localStorage.removeItem('storedCheckedIssues')
		localStorage.removeItem('storedCheckedIssuesDashboard')
		try {
			const { data } = await CatalogServices.postLogin({
				username: email,
				password,
				agency: 'firstagency',
			});
			userStore.user = data.user;
			userStore.token = data.token;
			userStore.isHidden = true;
			window.open(`/${searchParams}`, '_top');
		}
		catch (e) {
			console.error(e);
		}
	};

	@action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		this.form[name] = value;
	};

	@action toggleTypePassword = () => {
		this.isPasswordShow = !this.isPasswordShow;
	};
}

const loginForm = new LoginForm();
export default loginForm;
