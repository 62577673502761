import React from 'react';
import { clientStore } from '../../Client/store';
import { observer } from 'mobx-react';
import Helper from '../../../helper/index';
import CardDocList from '../../Client/components/CardDocList/CardDocList';
import WatchIssue from './WatchIssue';

interface PropsType {
	affairId: string;
}

const FeedlyAffair: React.FC<PropsType> = observer(() => {
	const { currentAffair } = clientStore;

	if (!currentAffair) return null;
	return (
		<div className={`affair_item affairDetails feedly-doc-affair`}>
			<div className="affair_block">
				<a href={currentAffair.link}
					target="_blank"
					rel="noopener noreferrer">
					<h3>
						{currentAffair.parsedDetails.title}
					</h3>
				</a>
				<WatchIssue />
			</div>
			<div className="affairDetails_description">
				<ul className="list-group affair_description">
					{currentAffair.parsedDetails.businessNumber &&
						<li className="list-group-item">
							<p className="bold">Nr:</p>
							<p>{currentAffair.parsedDetails.businessNumber}</p>
						</li>}
					{currentAffair.lastUpdateDate &&
						<li className="list-group-item">
							<p className="bold">Letzte Aktualisierung:</p>
							<p className="update-date">
								{Helper.normalizeDate(currentAffair.lastUpdateDate)}
							</p>
						</li>}
				</ul>
			</div>
			<CardDocList data={currentAffair.details} cardType='feedly' listType='affair' link={currentAffair.link} />
		</div>
	);
});

export default FeedlyAffair;
