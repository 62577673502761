import React from 'react';
import Button from '../../../shared/Button';
import forgotPassForm from '.././stores/ForgotPassForm';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import InputPassword from '../../../shared/Input/InputPassword';

const PasswordReset = observer(() => {
	return (
		<div className="login-container forgot" id="forgot-password-form">
			<div className="width-p-100 w-m-250">
				<h4 className="mb-3">Passwort ändern</h4>
				<figure className="mb-4">
				 Bitte geben Sie Ihr neues Passwort ein.
				</figure>
				<InputPassword
					value={forgotPassForm.form.password}
					onChange={forgotPassForm.onChange}
					errorOnField={forgotPassForm.formState.errorFields.password}
					placeholder="Neues Passwort"
					name="password"
					type={forgotPassForm.formState.passwordType}
					autocomplete="current-password"
					isPasswordShow={forgotPassForm.formState.isPasswordShow}
					toggleTypePassword={forgotPassForm.toggleTypePassword}
				/>
				<Button
					text="Passwort ändern"
					onClick={forgotPassForm.resetPass}
					className="btn btn-outline-primary"
				/>
				<figure>
					<Link to="/login">Zurück zur Anmeldung</Link>
				</figure>
			</div>
		</div>
	);
});

export default PasswordReset;
