import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { clientStore } from '../store';
import { IAffairCard } from '../interface/affair';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter, RouteComponentProps } from 'react-router';
import { Loader } from '../../../shared/Loader';
import Helper from '../../../helper';
import AffairCard from './AffairCards/index';

interface IPropsType {
	isAllAffairs: boolean;
}

type PropsType = RouteComponentProps & IPropsType;

@observer
class AffairsList extends Component<PropsType> {

	componentDidMount() {

		const params = new URLSearchParams(this.props.location.search)
		const affairId = params.get("affairId")
		const affairType = params.get("affairType")
		if (affairId && affairType) {
			clientStore.setCurrentAffair(affairId, affairType, [])
				.then(() => {
					this.props.history.push({
						pathname: "/",
						search: ""
					})
				})
				.catch(err => console.log(err))
		}

		var scrollGeneralList: any = document.querySelector('.general-list .affair-list');
		if (scrollGeneralList) {
			scrollGeneralList.scrollTo({ top: clientStore.positionGeneralList });
		}
	}

	componentDidUpdate() {
		const { isLoading } = clientStore;
		isLoading
			? Helper.showOverlay('overlay', <Loader />)
			: Helper.removeOverlay('overlay');
	}

	onScroll = () => {
		var el: any = document.querySelector('.general-list .affair-list');
		if (el) {
			el.addEventListener("scroll", (event: any) => {
				clientStore.positionGeneralList = el.scrollTop;
			})
		}
	}

	render() {
		const { affairs, handlePageClick, pagination, currentView, bucket } = clientStore;
		// console.log(bucket[0])
		if (currentView === 'affairs') {
			return (
				<div className="affair-list" onScroll={this.onScroll}>
					<InfiniteScroll
						pageStart={1}
						loadMore={handlePageClick}
						hasMore={pagination.hasMore}
						initialLoad={false}
						useWindow={false}
					>
						{affairs.map((affair: IAffairCard, i: number) => {
							return <AffairCard index={i} affair={affair} key={affair.id + i} filteredIssue={this.props.location.search} />
						})}
					</InfiniteScroll>
				</div>
			);
		} else {
			return (
				<div className="affair-list" onScroll={this.onScroll}>
					{bucket.map((affair: IAffairCard, i: number) => {
						return <AffairCard index={i} affair={affair} key={affair._id + i + 'bucket'} filteredIssue={this.props.location.search} isDeleted={true} />
					})}
				</div>
			)
		}
	}
}

export default withRouter(AffairsList);
