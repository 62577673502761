import React, { useState } from "react";
import { Observer } from "mobx-react"
import { clientStore } from '../../Client/store';
import { agencyStore } from "../../Agency/store";
import userStore from "../../../stores/UserStore";
import Spinner from "../../../shared/Spinner";

interface PropsType {

}

const WatchIssue = (props: PropsType) => {

    const { user } = userStore
    const { currentClientUsers } = agencyStore

    const { currentAffair, watchListLoading, addUserToWatchListHandler, removeUserFromWatchListHandler } = clientStore;
    const { watchList, docType } = currentAffair

    const checkIsAdminUser = (user: any) => {
        if (user) {
            return user.role == "agency-admin"
        } else {
            return false
        }
    }

    const toggleWatchStatus = (userId: string, userIsWatchingIssue: boolean) => {
        if (userIsWatchingIssue) {
            removeUserFromWatchListHandler({ user_id: userId, docType })
        } else {
            addUserToWatchListHandler({ user_id: userId, docType })
        }
    }

    const checkUserIsWatchingIssue = (userId: string) => {
        if (watchList) {
            return watchList.includes(userId)
        } else {
            return false
        }
    }

    const getUserId = (user: any) => {
        if (user) {
            return user.user_id
        } else {
            return false
        }
    }

    const [showWatcherList, setShowWatcherList] = useState(false)
    const isAdminUser = checkIsAdminUser(user)
    const currentUserId = getUserId(user)
    const isWatchedByCurrentUser = currentUserId ? checkUserIsWatchingIssue(currentUserId) : false

    return <Observer>
        {() => {
            return <div className="watcher_icon">
                <div className="watcher_icon_wrapper">

                    {isAdminUser
                        ? <>
                            <div onClick={() => setShowWatcherList(!showWatcherList)}>
                                <i className={"fas fa-eye"}></i>
                            </div>
                            {showWatcherList && <div className="watcher_dropdown_container">
                                <span
                                    className="close-button"
                                    onClick={() => setShowWatcherList(!showWatcherList)}
                                >&times;</span>
                                {watchListLoading && <Spinner />}
                                {currentClientUsers.map(user => {
                                    const userIsWatchingIssue = checkUserIsWatchingIssue(user._id)

                                    return <div className="watcher_email_container">
                                        <div onClick={() => toggleWatchStatus(user._id, userIsWatchingIssue)}>
                                            <i className={userIsWatchingIssue ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                                        </div>
                                        <span className="watcher_dropdown_email">{user.email}</span>
                                    </div>
                                })}
                            </div>}
                        </>
                        : <div onClick={() => toggleWatchStatus(currentUserId, isWatchedByCurrentUser)}>
                            <i className={isWatchedByCurrentUser ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                        </div>}
                </div>
            </div>
        }}
    </Observer>

}

export default WatchIssue