import React, { Component } from 'react';
import { clientStore } from '../store/index';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import DropDownItem from '../../../shared/DropDownItem';
import userStore from '../../../stores/UserStore';


type PropsType = RouteComponentProps & {
	dashboardFlag?: boolean;
	facetField?: any;
};


@observer
class IssuesList extends Component<PropsType> {

	state = {
		issuesList: clientStore.currentView === 'affairs'
	}

	toggleView = (mode: boolean) => () => {
		this.setState({ issuesList: mode });
	}

	async componentDidMount() {
		this.setDefaultRout();
	}
	componentDidUpdate(prevProps: PropsType, prevState: { issuesList: boolean }) {
		if (this.state.issuesList !== prevState.issuesList) {
			clientStore.changeView()
			this.setDefaultRout();
		}
	}

	setDefaultRout = () => {
		clientStore.checkedIssue = clientStore.issues.filter(issue => issue.isChecked).map(issue => issue._id);
	};

	exportExcel = () => {
		clientStore.setFilteredAffairs(false, false, true, true, false, '', true);
	}

	render() {
		const { handelChanger, isAllIssuesChecked } = clientStore;
		const { issuesList } = this.state;
		const checkClass = issuesList ? '' : 'checked';

		return (
			<aside className="sideBar_block">
				{!this.props.dashboardFlag && <button className={`affairs-btn delete ${checkClass}`} onClick={this.toggleView(false)}>
					<span>
						<i className="fas fa-trash-alt" style={{ fontSize: "1rem", marginRight: 8 }}></i>
						Papierkorb
					</span>
				</button>}
				{!this.props.dashboardFlag && <button className={`affairs-btn excel ${checkClass}`} onClick={() => {this.exportExcel()}}>
					<span>
						<i className="fas fa-file-excel" style={{ fontSize: "1rem", marginRight: 8 }}></i>
						Export
					</span>
				</button>}
				<DropDownItem defaultState={issuesList} title='Issues' onClick={this.toggleView(true)}>
					<ul>
						<li onClick={() => clientStore.allIssuesChecked(this.props.dashboardFlag, this.props.facetField)}><span className={`filter_item ${isAllIssuesChecked ? 'active_filter' : ''} all`}>Alle anwählen</span></li>
						{clientStore.issues.map(({ title, _id, isChecked }, key) => {
							const active = isChecked ? 'active_filter' : '';
							return (
								<li
									key={_id || key}
									onClick={() => handelChanger(_id, this.props.dashboardFlag, this.props.facetField)}
									className="children"
								>
									<span className={`filter_item ${isAllIssuesChecked ? 'active_filter' : ''} ${active}`}>{title}</span>
								</li>
							);
						})}
					</ul>
				</DropDownItem>
			</aside>
		);
	}
}

export default withRouter(IssuesList);
