import React from 'react';
import KeyWord from '../../../shared/KeyWord';
import { agencyStore } from '../store';
import IssueOverlay from '../overlays/IssueOverlay';
import { DeleteOverlay } from '../overlays/DeleteOverlay';
import DocumentsList from './DocumentsList';
import { observer } from 'mobx-react';

interface ICardProps {
	index: number;
	issueId: string;
}

const Cards: React.FC<ICardProps> = observer(({ index }) => {
	const issue = agencyStore.currentClientIssues[index];
	return (
		<div className="card">
			<div className="card-header d-flex" id={`heading${issue._id}`}>
				<div className='button-wrapper'>
					<button
						className="btn btn-link"
						data-toggle="collapse"
						data-target={`#collapse${issue._id}`}
						aria-expanded="true"
						aria-controls="collapseOne"
					>
						{issue.title}
					</button>
				</div>
				<div className="btn-group">
					<i
						className="far fa-edit"
						onClick={() =>
							agencyStore.showOverlay(
								'overlay',
								<IssueOverlay
									id="overlay"
									issue={JSON.parse(JSON.stringify(issue))}
									isEditOverlay={true}
								/>
							)
						}
					/>
					<i
						className="fas fa-trash"
						onClick={() =>
							agencyStore.showOverlay(
								'overlay',
								<DeleteOverlay id={'overlay'} issueId={issue._id} display={'issue'}/>
							)
						}
					/>
				</div>
			</div>

			<div
				id={`collapse${issue._id}`}
				className="collapse"
				aria-labelledby="headingOne"
				data-parent="#accordion"
			>
				<div className="card-body d-flex">
					{issue.filters.keywords.map((keyWord, key) => (
						<KeyWord keyWord={keyWord} key={key} />
					))}
				</div>
				{issue.documents && issue.documents.length ? (
					<ol>
						<DocumentsList documents={issue.documents} />
					</ol>
				) : null}
			</div>
		</div>
	);
});

export default Cards;
