import React, { Component } from 'react';
import defaultLogo from '../assets/img/logo.png';
import mindsetLogo from '../assets/img/mindset.png';
import { url } from '../services/http/config';
import userStore from '../stores/UserStore';
import { observer } from 'mobx-react';
import Login from '../pages/Login';
import { USER_ROLES } from '../types/user/user';
import Agency from '../pages/Agency/index';
import ClientPage from '../pages/Client';
import { develop } from '../services/http/config';
import { Link } from 'react-router-dom';

@observer
class Footer extends Component {
    render() {
        const { user, showMenu, logout } = userStore;
        const getUserComponent = (user: any): any => {
            if (!user) return Login;
            return user.role === USER_ROLES.AGENCY ? Agency : ClientPage;
        }
        const headerActions = [
            { label: 'Issue Feed', icon: 'fas fa-table', link: '/' },
            { label: 'Reports', icon: 'fas fa-chart-pie', link: '/dashboard' },
            { label: 'Benutzer', icon: 'fas fa-users', link: '/users', hidden: user && user.permissionLevel !== 'admin' }
        ]
        const segments = window.location.href.split('/');
        const activePage = segments[3] ? '/' + segments[3] : '/';
        return (
            <div className="footerActions">
                <div className="row">
                    {headerActions.map((data, i) => {
                        if (data.hidden) return <></>
                        return (
                            <div className="col" key={i}>
                                <a href={data.link} className={`${activePage === data.link ? 'active' : ''}`}>
                                    <i className={data.icon}></i>
                                    <div>{data.label}</div>
                                </a>
                            </div>
                        )
                    })}
                    <div className="col">
                        <a onClick={logout}>
                            <i className="fas fa-sign-out-alt"></i>
                            <div>Abmelden</div>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
};

export default Footer;
