import React from 'react';
import Modal from 'react-modal';
import Icon from '../Icon';
import ModalStore from './ModalStore';
import { observer } from 'mobx-react';

interface ModalProps {
	text?: string;
}

const CustomModal: React.FC<ModalProps> = observer(({
	text = ''
}) => {

  if( ModalStore.isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
  const clickHandler = () => {//will add async
    ModalStore.okCallback()
    ModalStore.closeModal()
  }
	return (
    <Modal isOpen={ModalStore.isOpen} 
           className="modal-window"
           overlayClassName="modal-overlay"
           ariaHideApp={false}

    >
        <div className='modal-window__icons'>
          <div></div>
          <Icon iconType='cancel' onClick={ModalStore.closeModal}/>
        </div>
        <h2 className='modal-window__title'>
          {ModalStore.modalConfig.title}
        </h2>
        <p className='modal-window__describtion'>
          {ModalStore.modalConfig.describtion}
        </p>
        <div className='modal-window__button-group'>
          <button className='btn btn-outline-danger mbtn' onClick={clickHandler}>
            {ModalStore.modalConfig.leftBtnText}
          </button>
          <button className='btn btn-outline-primary mbtn' onClick={ModalStore.closeModal}>
            {ModalStore.modalConfig.rightBtnText}
          </button>
        </div>
    </Modal>
	);
});

export default CustomModal;
