export const url = '/api';
//export const url = 'http://localhost:8080'
// export const url = 'https://www.monitoring.iwf.ch/api'
// export const url = 'http://api.monitoring.mindset.swiss:8080'
export const develop = {
	production: true,
};


// export const url = 'http://172.16.3.22:3003';
