import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import clientStore from '../../store/ClientStore';
import DatePicker from "react-datepicker";
import MultiSelect from "@kenshooui/react-multi-select";
import { Loader } from '../../../../shared/Loader';
import Helper from '../../../../helper';
import { RouteComponentProps } from 'react-router';

import "react-datepicker/dist/react-datepicker.css";
import "@kenshooui/react-multi-select/dist/style.css"

interface IState {
	isPolitmonitoringOpen: boolean,
	isMediamonitoringOpen: boolean,
	isArgusmonitoringOpen: boolean,
}

type PropsType = {
	dashboardFlag?: boolean;
	facetField?: any;
};

const defaultFilterSettings = {
	startDate: 0,
	endDate: new Date(),
}

@observer
class MultiFilterOverlay extends React.Component<PropsType, IState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isPolitmonitoringOpen: false,
			isMediamonitoringOpen: false,
			isArgusmonitoringOpen: false,
		}

		this.selectPolitSources = this.selectPolitSources.bind(this);
		this.selectMediaSources = this.selectMediaSources.bind(this);
		this.selectArgusLeads = this.selectArgusLeads.bind(this);
		this.selectAllArgusLeads = this.selectAllArgusLeads.bind(this);
	}

	componentDidUpdate() {
		const { isLoading } = clientStore;
		isLoading
			? Helper.showOverlay('overlay', <Loader />)
			: Helper.removeOverlay('overlay');
	}

	onClickPolitHandler = () => {
		this.setState({ isPolitmonitoringOpen: !this.state.isPolitmonitoringOpen })
	}

	onClickMediaHandler = () => {
		this.setState({ isMediamonitoringOpen: !this.state.isMediamonitoringOpen })
	}

	onClickArgusHandler = () => {
		this.setState({ isArgusmonitoringOpen: !this.state.isArgusmonitoringOpen })
	}

	handleChangeStartDate = (date: any) => {
		const formatDate = new Date(date);
		clientStore.startDate = formatDate.getTime();
	};

	handleChangeEndDate = (date: any) => {
		const formatDate = new Date(date);
		clientStore.endDate = formatDate.getTime();
	};

	selectPolitSources(selectedItems: any) {
		clientStore.selectedPolitSources = selectedItems;
	}

	selectMediaSources(selectedItems: any) {
		clientStore.selectedMediaSources = selectedItems;
	}
	selectArgusLeads(selectedItems: any) {
		clientStore.selectedArgusLeads = selectedItems;
	}

	selectAllArgusLeads(){
		clientStore.selectedArgusLeads = clientStore.argusSources;
	}

	removeAllFilters = () => {
		clientStore.startDate = defaultFilterSettings.startDate;
		clientStore.endDate = defaultFilterSettings.endDate;
		clientStore.selectedPolitSources = clientStore.politSources;
		clientStore.selectedMediaSources = clientStore.mediaSources.filter((source) => source.disabled === false);

		clientStore.isOpenMultiFilter = false;
		clientStore.setFilteredAffairs(false, false, true, true, this.props.dashboardFlag, this.props.facetField);
	}

	render() {
		return (
			<div className={`multi_filter_block ${clientStore.isOpenMultiFilter ? 'active' : ''}`}>
				<div className="timeperiod-filter">
					<p>Zeitspanne</p>
					<div className="dates">
						<div className="start-date">
							<DatePicker
								selected={clientStore.startDate}
								onChange={this.handleChangeStartDate}
								dateFormat="yyyy-MM-dd"
								maxDate={clientStore.endDate}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
								}}
							/>
							<i className="fa fa-calendar" aria-hidden="true"></i>
						</div>
						<p>bis</p>
						<div className="end-date">
							<DatePicker
								selected={clientStore.endDate}
								onChange={this.handleChangeEndDate}
								dateFormat="yyyy-MM-dd"
								minDate={clientStore.startDate}
								maxDate={defaultFilterSettings.endDate}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
								}}
							/>
							<i className="fa fa-calendar" aria-hidden="true"></i>
						</div>
					</div>
				</div>
				<div className="politsources-filter">
					<div className="block-header">
						<p>Quellen Politmonitoring</p>
						<button className="toggle-button" onClick={this.onClickPolitHandler}>
							<i className="fas fa-chevron-down"></i>
						</button>
					</div>

					{this.state.isPolitmonitoringOpen ? <MultiSelect
						items={clientStore.politSources}
						selectedItems={clientStore.selectedPolitSources}
						onChange={this.selectPolitSources}
					/> : null}
				</div>
				<div className="mediasources-filter">
					<div className="block-header">
						<p>Quellen Mediamonitoring</p>
						<button className="toggle-button" onClick={this.onClickArgusHandler}>
							<i className="fas fa-chevron-down"></i>
						</button>
					</div>

					{this.state.isArgusmonitoringOpen ? <Fragment>
						<div style={{ fontSize: "12px" }}>Max 20</div>
						<div style={{ cursor: "pointer", marginBottom: "8px" }}
							onClick={this.selectAllArgusLeads}
						><i className="fas fa-check-square" /> Select All</div>
						<MultiSelect
							items={clientStore.argusSources}
							selectedItems={clientStore.selectedArgusLeads}
							onChange={this.selectArgusLeads}
							maxSelectedItems={20}
						/>
					</Fragment> : null}
				</div>
				<div className="filter-buttons">
					<button type="button" className="btn btn-outline-secondary" onClick={this.removeAllFilters}>Filter löschen</button>
					<button type="button" className="btn btn-primary" onClick={() => clientStore.setFilteredAffairs(false, false, false, false, this.props.dashboardFlag, this.props.facetField)}>Anwenden</button>
				</div>
			</div>
		)
	}
}
export default MultiFilterOverlay;