const filterDocs = (data: Array<object>): Array<object> => {
	const result = JSON.parse(JSON.stringify(data));

	return result.filter(
		(doc: any) =>
			(doc.title || doc.description || doc.designation) && (doc.link || doc.url)
	);
};

export default filterDocs;
