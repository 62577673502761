import React from 'react';

interface InputProps {
	name: string;
	value: string;
	placeholder: string;
	accept: string;
	errorOnField?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ivalidFieldClass = 'invalid';

const InputFile: React.FC<InputProps> = ({
	name,
	value,
	placeholder,
	onChange,
	accept,
	errorOnField = '',
}) => {
	let className = 'file_download';
	const errorClass = !errorOnField ? '' : ivalidFieldClass;
	className = `${className} ${errorClass}`;
	const displayingValue = value.replace('C:\\fakepath\\',"")
	return (
		<div className={className}>
			<label className="custom-file-upload">
				<input
					type="file"
					value={value}
					onChange={onChange}
					name={name}
					id="file_upload"
					accept={accept}
				/>
				{placeholder}
				<span>{displayingValue}</span>
			</label>
			{errorOnField.length ? (
				<span className="errorOnField">{errorOnField}</span>
			) : null}
		</div>
	);
};

export default InputFile;
