import React from 'react';
import Input from '../../../shared/Input';
import { agencyDocInfoFormStore, agencyStore } from '../store';
import { observer } from 'mobx-react';
import Calendar from 'react-calendar';
import InputFile from '../../../shared/Input/InputFile';
import Button from '../../../shared/Button';
import CustomSelect from '../../../shared/CustomSelect';

interface DocInfoFormProps {
	id: string;
}

@observer
class DocInfoForm extends React.Component<DocInfoFormProps> {
	state = {
		date: new Date(),
	};
	componentDidMount() {
		agencyDocInfoFormStore.setOptions();
	}

	componentWillUnmount() {
		agencyDocInfoFormStore.resetStore();
	}

	render() {
		const {
			docInfoFields,
			onChange,
			handleChangeSelect,
			onChangeDate,
			filePDF,
			onChangeFile,
			onFormSubmit,
			formState,
		} = agencyDocInfoFormStore;

		const {
			options,
			selectedOptions,
		} = agencyDocInfoFormStore.issueMultiSelect;

		return (
			<form className="docInfo_form" onSubmit={onFormSubmit}>
				<button
					className="closeOverlay_btn"
					onClick={() => agencyStore.removeOverlay(this.props.id)}
				>
					Abbrechen
				</button>
				<h5>Dokument anlegen</h5>
				<Input
					name="title"
					type="text"
					value={docInfoFields.title}
					placeholder="Titel"
					onChange={onChange}
					errorOnField={formState.errorFields.title}
				/>
				<CustomSelect
					value={selectedOptions}
					onChange={handleChangeSelect}
					placeholder="Issue"
					options={options}
					isMulti={true}
					errorOnField={formState.errorFields.issue}
				/>
				<Input
					name="source"
					type="text"
					errorOnField={formState.errorFields.source}
					value={docInfoFields.source}
					onChange={onChange}
					placeholder="Quelle"
				/>
				<div className="form_controls">
					<textarea
						name="lead"
						value={docInfoFields.lead}
						placeholder="Lead"
						onChange={onChange}
					/>
					<div className="calendar">
						<h6>Datum auswählen</h6>
						<Calendar onChange={onChangeDate} value={this.state.date} />
					</div>
				</div>
				<InputFile
					onChange={onChangeFile}
					errorOnField={formState.errorFields.filePDF}
					placeholder="Datei hochladen"
					name="pdf"
					value={filePDF.value}
					accept=".pdf, .mp3, .mp4"
				/>
				<div className="documentInfo_form__btn-group">
					<Button
						text="Dokument anlegen"
						className="btn btn-outline-success"
						type="submit"
						// disabled={isDisableFormBtn}
					/>
					<Button
						text="Abbrechen"
						onClick={() => agencyStore.removeOverlay(this.props.id)}
						className="btn btn-outline-danger"
					/>
				</div>
			</form>
		);
	}
}

export default DocInfoForm;
