import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import forgotPassForm from '../stores/ForgotPassForm';

interface IDefaultLiterals {
	title: string;
	text: string;
}

interface ISuccessLiterals {
	[key: string]: IDefaultLiterals;
	request: IDefaultLiterals;
	reset: IDefaultLiterals;
}

const successLiterals: ISuccessLiterals = {
	request: {
		title: `Die Email wurde versendet`,
		text: `Bitte überprüfen Sie Ihr Postfach inkl. Spamordner.`,
	},
	reset: {
		title: `Passwort erfolgreich geändert`,
		text: ``,
	},
};

const RequestSuccess = observer(() => {
	const classComplete = forgotPassForm.formState.success
		? 'load-complete'
		: '';

	return (
		<div className="login-container forgot" id="forgot-password-form">
			<div className="width-p-100 w-m-250">
				<h3>{successLiterals[forgotPassForm.formState.successType].title}</h3>
				<p>{successLiterals[forgotPassForm.formState.successType].text}</p>
				<div className={`circle-loader ${classComplete}`}>
					<div className="checkmark draw" />
				</div>
				<figure>
					<Link to="/login">
						<span className="forget-password">Zurück zur Anmeldung</span>
					</Link>
				</figure>
			</div>
		</div>
	);
});

export default RequestSuccess;
