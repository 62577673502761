import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import { agencyStore, agencyClientFormStore } from '../store/index';
import InputFile from '../../../shared/Input/InputFile';
import InputPassword from '../../../shared/Input/InputPassword';
import CustomSelect from '../../../shared/CustomSelect';

type UserOverlayProps = {
    id: string;
    isEditOverlay: boolean;
};

@observer
class UserOverlay extends Component<UserOverlayProps> {
    componentDidMount() {
        agencyClientFormStore.setIsEditOverlay(this.props.isEditOverlay);
    }

    componentWillUnmount() {
        agencyClientFormStore.resetStore();
    }

    disableSubmit = () => {
        const { email, client, password } = agencyClientFormStore.userInfo;
        const { errorFields } = agencyClientFormStore.formState;
        let foundError: boolean = false
        for (const error in errorFields) {
            if (!!errorFields[error].length) {
                foundError = true
                break
            }
        }

        return !password || !email || foundError
    }

    render() {
        const { email, password, permissionLevel } = agencyClientFormStore.userInfo;
        const { errorFields } = agencyClientFormStore.formState;
        const {
            onChangeUser,
            onChangeUserSelect,
            onFormSubmitUser,
            toggleTypePassword,
            isPasswordShow,
        } = agencyClientFormStore;
        const passwordType = isPasswordShow ? 'text' : 'password';
        const titleOverlay = this.props.isEditOverlay
            ? 'Speichern'
            : 'Neuen Kunden anlegen';

        return (
            <div className="clientOverlay">
                <button
                    className="closeOverlay_btn"
                    onClick={() => agencyStore.removeOverlay(this.props.id)}
                >
                    Abbrechen
				</button>
                <form className="createClientOverlay_form" onSubmit={onFormSubmitUser}>
                    <Input
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Email"
                        errorOnField={errorFields.email}
                        onChange={onChangeUser}
                    />
                    {!this.props.isEditOverlay && <InputPassword
                        name="password"
                        type={passwordType}
                        errorOnField={errorFields.password}
                        value={password}
                        placeholder="Passwort"
                        onChange={onChangeUser}
                        autocomplete="current-password"
                        isPasswordShow={isPasswordShow}
                        toggleTypePassword={toggleTypePassword}
                    />}
                    <div
                        className='additional-select-container'
                    >
                        <select
                            className='additional-select'
                            value={permissionLevel}
                            name="permissionLevel"
                            onChange={onChangeUserSelect}
                        >
                            <option value="user">Benutzer</option>
                            <option value="admin">Admin</option>
                        </select>
                    </div>

                    {/* <InputFile
                        onChange={onChangeUser}
                        placeholder="Logo hochladen"
                        name="logo"
                        value={agencyClientFormStore.logo.value}
                        accept=".jpg, .jpeg, .png"
                    /> */}
                    <div className="createClientOverlay_form__btn-group">
                        <Button
                            text={titleOverlay}
                            className="btn btn-outline-success"
                            type="submit"
                            disabled={this.disableSubmit()}
                        />
                        <Button
                            text="Abbrechen"
                            onClick={() => agencyStore.removeOverlay(this.props.id)}
                            className="btn btn-outline-danger"
                        />
                    </div>
                </form>
            </div>
        );
    }
}

export default UserOverlay;
