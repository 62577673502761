import React from 'react';

interface InputProps {
	name: string;
	type: string;
	value: string;
	placeholder: string;
	className?: string;
	errorOnField?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	autocomplete?: string;
}

const ivalidFieldClass = 'invalid';

const Input: React.FC<InputProps> = ({
	name,
	type,
	value,
	placeholder,
	onChange,
	className = 'defaultInput',
	errorOnField,
	onFocus = () => {},
	onBlur = () => {},
	autocomplete = '',
}) => {
	const errorClass = !errorOnField ? '' : ivalidFieldClass;
	className = `${className} ${errorClass}`;

	return (
		<div className="defaultInput_container">
			<input
				name={name}
				type={type}
				value={value}
				className={className}
				placeholder={placeholder}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				autoComplete={autocomplete}
			/>
			{errorOnField ? (
				<span className="errorMessage">{errorOnField}</span>
			) : null}
		</div>
	);
};

export default Input;
