import React, { useEffect, useMemo, useState } from 'react'
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import { agencyStore } from '../Agency/store';

const ReactQuillWrapper = (props: any) => {
    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link', 'image']
            ],
            handlers: {
                image: props.imageHandler
            }
        },
        mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],
            source: (searchTerm: any, renderList: any, mentionChar: any) => {
                let values: any = [];
                if (mentionChar === "@") {
                    values = props.userSuggestions;
                }
                if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                } else {
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (
                            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                        )
                            matches.push(values[i]);
                    renderList(matches, searchTerm);
                }
            }
        }
    }), [])

    return <ReactQuill
        ref={props.ref}
        onChange={(text) => {
            props.clientStore.commentForm.comment = text
        }}
        placeholder="einen Kommentar schreiben ..."
        modules={modules}
    />
}

export default ReactQuillWrapper