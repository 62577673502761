import React, { Component, ReactElement } from 'react';
import { observer } from 'mobx-react';
import ForgotPasswordRequest from './components/ForgotPasswordRequest';
import forgotPassForm from './stores/ForgotPassForm';
import RequestSuccess from './components/RequestSuccess';
import { withRouter, RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import PasswordReset from './components/PasswordReset';

interface IComponents {
	[key: string]: ReactElement;
	ForgotPasswordRequest: ReactElement;
	RequestSuccess: ReactElement;
	['password-reset']: ReactElement;
}

interface PathParamsType {
	[`password-reset-token`]: string;
}

type PropsType = RouteComponentProps<PathParamsType> & {};
@observer
class ForgotPassword extends Component<PropsType> {
	componentDidMount() {
		const path = this.props.location.pathname.split('/')[1];
		if (path === 'password-reset') {
			const search = queryString.parse(this.props.location.search);
			forgotPassForm.setComponent(path);

			if (search && search[`password-reset-token`]) {
				const token = search[`password-reset-token`];
				forgotPassForm.setToken(token);
			}
		}
	}
	componentWillUnmount() {
		forgotPassForm.resetStore();
	}
	render() {
		const components: IComponents = {
			ForgotPasswordRequest: <ForgotPasswordRequest />,
			RequestSuccess: <RequestSuccess />,
			[`password-reset`]: <PasswordReset />,
		};
		return components[forgotPassForm.formState.component];
	}
}

export default withRouter(ForgotPassword);
