import React, { useEffect, useState } from "react";
import { Observer } from "mobx-react"
import { clientStore } from '../../Client/store';
import { agencyStore, agencyClientFormStore } from "../store";
import userStore from "../../../stores/UserStore";
import Spinner from "../../../shared/Spinner";

interface PropsType {
    user: any
}

const UserWatchIssue = (props: PropsType) => {

    const { user } = props
    const { updateWatcherStatus } = agencyClientFormStore
    const [userWatchingIssue, setUserWatchingIssue] = useState(user.isWatcher)

    // useEffect(() => {
    //     if (user) {
    //         setUserWatchingIssue(user.isWatcher)
    //     }
    // }, [user])

    const toggleWatchStatus = async () => {
        setUserWatchingIssue(!userWatchingIssue)
        await updateWatcherStatus(!userWatchingIssue, user)
    }

    return <Observer>
        {() => {
            return <div className="watcher_icon">
                <div className="watcher_icon_wrapper">
                    <div onClick={toggleWatchStatus}>
                        <i className={userWatchingIssue ? "fas fa-eye" : "fas fa-eye-slash"}></i>
                    </div>
                </div>
            </div>
        }}
    </Observer>

}

export default UserWatchIssue