import React, { Component } from 'react';
import { agencyStore } from '../store';
import { observer } from 'mobx-react';
import Helper from '../../../helper/index';

@observer
export default class CrawlerStatus extends Component {
	componentDidMount(): void {
		agencyStore.getCrawlerStatusData();
	}

	render() {
		const { crawlerStatus } = agencyStore;
		return (
			<div className="sideBar_block">
				<ul>
					{crawlerStatus.map(({ last, _id }) => {
						const status = last.info.ok ? 'completed' : 'failed';
						const date = Helper.normalizeDate(last.date);
						return (
							<li className={`crawlerStatus ${status}`} key={_id}>
								<span>{_id}</span>
								<span>{date}</span>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}
