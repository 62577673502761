import React, { useState, MouseEvent, ReactElement, useEffect } from 'react';

interface ButtonProps {
  title: string;
  defaultState?: boolean;
	className?: string;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
  children: ReactElement | HTMLElement | JSX.IntrinsicElements;
  titleClassName?: string; 
}

const DropDownItem: React.FC<ButtonProps> = ({
	title = '',
	className = '',
	onClick = () => {},
  children = null,
  defaultState = false,
  titleClassName='drop-down__title',
}) => {
  const [displaying, changeDisplaying] = useState(defaultState)
  useEffect(() => {
    changeDisplaying(defaultState);
  },[defaultState])

  const clickHandler = (event: MouseEvent<HTMLElement>) => {
    changeDisplaying(!displaying)
    onClick(event)
  }
  const checkedClass = displaying ? '-checked' : ''
	return (
    <div className={`drop-down ${className}`}>
      <div className={`${titleClassName} ${checkedClass}`} onClick={clickHandler}>
        {/* <i className="far fa-file" style={{backgroundColor: "white", color: "#858585", fontSize: "1.5rem", marginRight: "10px", marginLeft: "10px", borderRadius: "4px", borderTopRightRadius: "10px", boxShadow: "2px solid #858585"}}></i> */}
        <span style={{ marginRight: 5 }}>
          {displaying ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}
        </span>
        <span style={{ marginRight: "10px" }}>{title}</span>
      </div>
      <div className={`drop-down__container ${checkedClass}`}>
        {children}
      </div>
    </div>
	);
};

export default DropDownItem;