enum STORAGE_CONST {
	USER = 'USER',
	TOKEN = 'auth_token',
	LOGO = 'logo',
}

class StorageService {
	getItem(key: STORAGE_CONST): any {
		try {
			const itemFromStorage = localStorage.getItem(key);
			if (!itemFromStorage) return null;
			return JSON.parse(itemFromStorage);
		} catch (e) {
			return null;
		}
	}
	setItem(key: STORAGE_CONST, value: any): any {
		try {
			const stringifyedItem = JSON.stringify(value);
			localStorage.setItem(key, stringifyedItem);
			return value;
		} catch (e) {
			return null;
		}
	}
}
const storageService = new StorageService();
export { storageService, STORAGE_CONST };
