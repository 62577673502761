import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import Agency from './pages/Agency/index';
import Header from './shared/Header';
import { USER_ROLES } from './types/user/user';
import userStore from './stores/UserStore';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword/index';
import { observer } from 'mobx-react';
import ClientPage from './pages/Client';
import MobileButtonExit from './pages/Client/components/SideAffairCard/MobileButtonExit';
import clienStore from './pages/Client/store/ClientStore';
import {
	ToastsContainer,
	ToastsStore,
	ToastsContainerPosition,
} from 'react-toasts';
import { storageService, STORAGE_CONST } from './services/StorageService';
import Modal from './shared/Modal/Modal';
import { isMobileDevice } from 'react-select/lib/utils';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Footer from './shared/Footer';

@observer
export default class MainRouter extends Component {
	async componentDidMount() {
		storageService.getItem(STORAGE_CONST.USER);
	}
	getUserComponent(user: any): any {
		if (!user) return Login;
		return user.role === USER_ROLES.AGENCY ? Agency : ClientPage;
	}
	getDashboardComponent(user: any): any {
		if (!user) return Login;
		return user.role === USER_ROLES.AGENCY ? redirectToLanding : Dashboard;
	}

	checkPermissionLevel(user: any): any {
		if (!user) return Login;
		return user.permissionLevel !== 'admin' ? redirectToLanding : Users;
	}

	render() {
		this.getUserComponent(userStore.user) === Agency ? (document.body.style.background = '#f1f1f1') : (document.body.style.background = '#ffffff');
		return (
			<Router>
				{clienStore.isMobileButtonExit && isMobileDevice() ? <MobileButtonExit /> : <Header />}
				<div className={`${this.getUserComponent(userStore.user) === Agency ? 'agency ' : ''}container`}>
					<Switch>
						<Route path="/login" component={Login} />

						<Route path="/forgot-password" component={ForgotPassword} />
						<Route path="/password-reset" component={ForgotPassword} />
						<Route path="/dashboard" component={this.getDashboardComponent(userStore.user)} />
						<Route path="/users" component={this.checkPermissionLevel(userStore.user)} />

						<PrivateRoute
							isAuthenticated={userStore.user}
							path="/"
							component={this.getUserComponent(userStore.user)}
						/>
					</Switch>
					<ToastsContainer
						store={ToastsStore}
						position={ToastsContainerPosition.BOTTOM_RIGHT}
					/>
				</div>
				{userStore.user && userStore.user != null ? isMobileDevice() ? <Footer /> : null : null}
				<Modal />
			</Router>
		);
	}
}

const PrivateRoute = ({ component, isAuthenticated, ...rest }: any) => {
	const routeComponent = (props: any) => {
		return isAuthenticated ? (
			React.createElement(component, props)
		) : (
			<Redirect to={{ pathname: '/login', search: props.location.search }} />
		);
	}
	return <Route {...rest} render={routeComponent} />;
};


const redirectToLanding = (): any => {
	return <Redirect to={{ pathname: '/' }} />
}