import { observable, action } from 'mobx';
import ClientStore from '../../pages/Client/store/ClientStore';

const defaultModalConfig = {
	title: 'DEFAULT TITLE',
	describtion: 'Default description',
	leftBtnText: 'OK',
	rightBtnText: 'NO',
};

interface IModalConfig {
	title: string;
	describtion: string;
	leftBtnText: string;
	rightBtnText: string;
}
class ModalStore {
	@observable isOpen: boolean = false;
	@observable okCallback: any = () => {};
	@observable modalConfig: IModalConfig = defaultModalConfig;

	@action openModal = (type: string, config: any) => () => {
		this.isOpen = true;
		if (type === 'remove') {
			this.modalConfig = {
				title: 'Löschen',
				describtion: 'Möchten Sie das Geschäft in den Papierkorb verschieben?',
				leftBtnText: 'Löschen',
				rightBtnText: 'Abbrechen',
			};
			this.okCallback = ClientStore.deleteAffair(config);
		}
		if (type === 'restore') {
			this.modalConfig = {
				title: 'Wiederherstellen',
				describtion: 'Möchten Sie das Geschäft wiederherstellen?',
				leftBtnText: 'Wiederherstellen',
				rightBtnText: 'Abbrechen',
			};
			this.okCallback = ClientStore.restoreAffair(config);
		}
	};
	@action closeModal = () => {
		this.isOpen = false;
	};
}

const modalStore = new ModalStore();
export default modalStore;
