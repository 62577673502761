import { observable, action } from 'mobx';
import validator from '../../../validator';
import {
	IformState,
	IdocInfoFields,
	IissueMultiSelect,
	IDefaultFile,
} from '../interface/docInfo';
import agencyStore from './AgencyStore';
import { literals } from '../../../validator/literals';

const formState = {
	errorFields: {
		title: '',
		source: '',
		issue: '',
		filePDF: '',
		lead: '',
	},
	issue: [],
};

const issueMultiSelect = {
	options: [],
	selectedOptions: [],
};

const defaultDocInfoFields = {
	lead: '',
	title: '',
	source: '',
	lastUpdateDate: new Date().getTime() / 1000,
};

const defaultFilePDF = {
	file: {},
	value: '',
};

class AgencyDocInfoFormStore {
	@observable formState: IformState = { ...formState };

	@observable issueMultiSelect: IissueMultiSelect = { ...issueMultiSelect };

	@observable docInfoFields: IdocInfoFields = { ...defaultDocInfoFields };

	@observable filePDF: IDefaultFile = { ...defaultFilePDF };

	@action setOptions = () => {
		this.issueMultiSelect.options = agencyStore.currentClientIssues.map(
			issue => {
				return { value: issue._id, label: issue.title };
			}
		);
	};

	private setError = (
		name: string,
		error: { isFieldValid: boolean; errorMessage: string }
	) => {
		if (!error.isFieldValid)
			return (this.formState.errorFields[name] = error.errorMessage);
		else return (this.formState.errorFields[name] = '');
	};

	private checkForm = () => {
		let isFormValid = true;
		const { lead, title, source } = this.docInfoFields;
		const fields: { [key: string]: string } = {
			lead,
			title,
			source,
		};
		for (let key in fields) {
			const error = validator.validateField(key, fields[key]);
			isFormValid = this.setError(key, error) ? false : true;
		}

		if (!this.formState.issue.length) {
			isFormValid = false;
			this.formState.errorFields.issue = literals.issue.empty;
		}

		if (!this.filePDF.value) {
			isFormValid = false;
			this.formState.errorFields.filePDF = literals.file.empty;
		}

		return isFormValid;
	};

	@action onChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { value, name } = event.target;
		const error = validator.validateField(name, value);

		this.setError(name, error);
		this.docInfoFields[name] = value;
	};

	@action onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (event.target.files) {
			this.formState.errorFields.filePDF = '';
			this.filePDF = {
				...this.filePDF,
				value: value,
				file: event.target.files[0],
			};
		}
	};

	@action onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
		evt.preventDefault();
		const isFormValid = this.checkForm();
		if (isFormValid) {
			await agencyStore.uploadPDf({
				...this.filePDF,
				docInfo: { ...this.docInfoFields, targetIssues: this.formState.issue },
			});

			agencyStore.getClientIssues(agencyStore.currentClient._id);
			agencyStore.removeOverlay('overlay');
		}
	};

	@action handleChangeSelect = (value: any) => {
		const issue = value.map(
			(item: { value: string; label: string }) => item.value
		);

		this.issueMultiSelect.selectedOptions = [...value];
		if (this.issueMultiSelect.selectedOptions.length) {
			this.formState.errorFields.issue = '';
		}
		this.formState.issue = issue;
	};

	@action onChangeDate = (date: any) => {
		this.docInfoFields.lastUpdateDate = new Date(date).getTime() / 1000;
	};

	public getDocInfo = () => {
		return {
			...this.docInfoFields,
			source: this.formState.issue,
		};
	};

	@action resetStore = () => {
		this.formState = { ...formState };
		this.issueMultiSelect = { ...issueMultiSelect };
		this.docInfoFields = { ...defaultDocInfoFields };
		this.filePDF = { ...defaultFilePDF };
	};
}

const agencyDocInfoFormStore = new AgencyDocInfoFormStore();
export default agencyDocInfoFormStore;
