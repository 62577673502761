import { literals } from './literals';

interface IvalidatorTypes {
	[key: string]: (value: string) => string | false;
	email: (value: string) => string | false;
	username: (value: string) => string | false;
}

class Validator {
	//   VALIDATOR METHODS
	private validatorEmail = (value: string) => {
		/* eslint-disable */
		const regexp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const result = !regexp.test(String(value).toLowerCase());
		if (result) return literals.email.invalid;
		return false;
	};

	private setSimpleValidation = ( minLength: number = 3, maxLength: number = 15) => {
		return (value: string) => {
			if (this.checkMinValue(value, minLength)) return `${literals.short} ${minLength} sein`;
			if (this.checkMaxValue(value, maxLength)) return `${literals.long} ${maxLength} sein`; 
			return false;
		}
	};

	private validatorUsername = (value: string) => {
		let error = '';

		const arr = value.split('');
		arr.forEach(item => {
			if (!item.match(/[a-z|0-9]/i)) error = literals.username.invalid;
		});

		return this.setSimpleValidation()(value) || error;
	};

	//  TYPES VALIDATOR METHODS
	validatorTypes: IvalidatorTypes = {
		email: this.validatorEmail,
		username: this.validatorUsername,
		title: this.setSimpleValidation(3,1000),
	};

	private getValidatorType = (type: string) => {
		for (let key in this.validatorTypes) {
			if (type === key) return this.validatorTypes[type];
		}
		return false;
	};

	// HELPER METHODS
	private checkMinValue = (value: string, minValue: number) => {
		return value.length < minValue;
	};

	private checkMaxValue = (value: string, maxValue: number) => {
		return value.length > maxValue;
	};

	// MAIN VALIDATOR METHOD
	public validateField = (type: string, value: any) => {
		const { getValidatorType } = this;
		if (value) {
			const validatorType = getValidatorType(type);

			if (!validatorType) {
				// console.warn(`validator type ${type} does not exist`);
				return { isFieldValid: true, errorMessage: '' };
			}

			const result = validatorType(value);
			if (result) {
				return { isFieldValid: false, errorMessage: result };
			} else {
				return { isFieldValid: true, errorMessage: '' };
			}
		} else {
			const errorMessage = literals[type] ? literals[type].empty : '';
			const error = { isFieldValid: false, errorMessage };
			return error;
		}
	};
}

const validator = new Validator();
export default validator;
