import http from './http';
import { Iissue } from '../pages/Agency/interface/issue';
import { ICreateClientData, ICreateUserData } from '../pages/Agency/interface/createClint';
import { Ipagination } from '../pages/Client/interface/paginstion';

class CatalogServices {
	static postLogin(data: {
		username: string;
		password: string;
		agency: string;
	}) {
		return http.post(`/v1/auth/login`, data);
	}

	static postComment(data: any, currentAffairID: string) {
		return http.put(`/v1/rest/affair-comment/${currentAffairID}`, data);
	}

	static addUserToWatchList(data: any, currentAffairID: string) {
		return http.post(`/v1/rest/affair-watch-list/${currentAffairID}`, data);
	}

	static removeUserfromWatchList(data: any, currentAffairID: string) {
		return http.post(`/v1/rest/affair-watch-list-remove/${currentAffairID}`, data);
	}

	static postReview(data: any, currentAffairID: string) {
		return http.put(`/v1/rest/affair-review/${currentAffairID}`, data);
	}

	static loginAs(data: { _id: string; agency: string }) {
		return http.post(`/v1/rest/login-as`, data);
	}

	static getUserIssues(userid: string) {
		return http.get(`/v1/rest/user/${userid}/issues`);
	}

	static getClientUsers(userid: string) {
		return http.get(`/v1/rest/sub-user/${userid}/users`);
	}

	static getBucket(userId: string) {
		return http.get(`/v1/rest/bucket/${userId}`);
	}

	static deleteUser(userId: string) {
		return http.delete(`/v1/rest/user/${userId}`);
	}

	static deleteSubUser(userId: string) {
		return http.delete(`/v1/rest/sub-user/${userId}`);
	}

	static moveToBucket(data: { type: string; target_id: string }) {
		console.log(data)
		return http.post('/v1/rest/bucket', data);
	}

	static restoreAffair(id: string, target_id: string,) {
		return http.delete(`/v1/rest/bucket/${id}`, { params: { target_id } })
	}

	static getAllClients() {
		return http.get('/v1/search/user', {
			params: {
				rows: 1000,
			},
		});
	}

	static editIssue(issue: Iissue, id: string) {
		return http.put(`/v1/rest/issue/${id}`, issue);
	}

	static postCreateIssue(issue: Iissue) {
		return http.post(`/v1/rest/issue/`, issue);
	}

	static postCreatePriority(
		type: string,
		target_id: string,
		value: any,
	) {
		return http.post(`/v1/rest/priorities/`, {
			type,
			target_id,
			value,
		});
	}


	static assignedIssueToClient(clintId: string, issues: Array<string>) {
		return http.put(`/v1/rest/user/${clintId}`, { issues });
	}

	static deleteIssue(id: string) {
		return http.delete(`/v1/rest/issue/${id}`);
	}

	static createClient(clientInfo: ICreateClientData) {
		return http.post(`/v1/rest/user/`, clientInfo);
	}

	static createSubUser(clientInfo: ICreateUserData) {
		return http.post(`/v1/rest/sub-user/`, clientInfo);
	}

	static postAddClientLogo(logo: FormData, userid: string) {
		return http.post(`/v1/rest/user/${userid}/avatar`, logo);
	}

	static getUserLogo(userId: string) {
		return http.get(`/v1/rest/user/${userId}/avatar`);
	}

	static putEditClient(
		clientId: string,
		clientInfo: { [key: string]: any }
	) {
		return http.put(`/v1/rest/user/${clientId}`, clientInfo);
	}

	static putEditUser(
		userInfo: { [key: string]: any }
	) {
		return http.put(`/v1/rest/sub-user`, userInfo);
	}

	static getAllSources() {
		return http.get(`/v1/rest/source`);
	}
	static getFilterSources() {
		return http.get(`/v1/rest/filter-sources`);
	}

	static getFilteredAffairs(issues: Array<string>, pagination: Ipagination, search: any, priorityFilter: string, startDate: number, endDate: number, politSourcesId: any, mediaSourcesId: any, argusLeads: any, updateFilters: boolean | undefined, resetFilters?: boolean | undefined, dashboardFlag: Boolean = false, facetField?: any, exportFlag: Boolean = false) {
		const { start, rows } = pagination;
		return http.post(`/v1/search/my-feed`, {
			issues,
			start,
			rows: dashboardFlag ? 0 : exportFlag ? 500 : rows,
			search,
			priority: priorityFilter,
			dateMin: startDate,
			dateMax: endDate,
			source_filter: [...politSourcesId, ...mediaSourcesId],
			lead_filter: argusLeads,
			updateFilters,
			resetFilters,
			dashboardFlag,
			facetField,
			exportFlag,
		});
	}

	static getIssueInfo(issueId: string) {
		return http.get(`/v1/search/issue/${issueId}`);
	}

	static getAffair(
		affairId: string,
		affairType: string,
		keywords: Array<Array<string>>,
	) {
		return http.get(`/v1/search/${affairType}/${affairId}`, {
			params: {
				affairType,
				keywords,
			},
		});
	}

	static getClient(clientId: string) {
		return http.get(`/v1/rest/user/${clientId}`);
	}

	static postUploadPDF(file: FormData) {
		return http.post(`/v1/rest/media`, file);
	}

	static getFeedlyTag() {
		return http.get(`/v1/rest/feedly-tag`);
	}

	static getCrawlerStatus() {
		return http.get('/v1/rest/scraper');
	}

	static postResetPasswordRequest(email: string) {
		return http.post('/v1/auth/password-reset-request', {
			email,
			agency: 'firstagency',
		});
	}

	static postResetPassword(data: {
		password: string;
		[`password-reset-token`]: any;
	}) {
		return http.post('/v1/auth/password-reset', data);
	}

	static getDocsForCurrentIssue(issueId: string) {
		return http.get('/v1/search/simple/media', {
			params: {
				query: {
					targetIssues: issueId,
				},
			},
		});
	}
}

export default CatalogServices;
