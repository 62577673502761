import React, { MouseEvent, ReactElement } from 'react';

interface ButtonProps {
	text?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
	children?: ReactElement | HTMLElement | JSX.IntrinsicElements;
	disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
	text = '',
	className = '',
	type = 'button',
	onClick = () => {},
	children = null,
	disabled = false,
}) => {
	return (
		<button
			onClick={onClick}
			className={className}
			type={type}
			disabled={disabled}
		>
			{text}
			{children}
		</button>
	);
};

export default Button;
