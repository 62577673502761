import React from 'react';
import { IAffairCard } from '../../interface/affair';

interface IContent {
	affair: IAffairCard;
}

const PolitCardContent: React.FC<IContent> = ({ affair }) => {
	const { source, type, creator } = affair;

	return (
		<div className="content_affair">
			<ul className="list-group affair_description">
				{source && <li className="list-group-item">
					<p className="bold">Quelle:</p>
					<p className="">{source}</p>
				</li>}
				{type && <li className="list-group-item">
					<p className="bold">Geschäftstyp:</p>
					<p>{type}</p>
				</li>}
				{creator && <li className="list-group-item">
					<p className="bold">Urheber:</p>
					<p>{creator}</p>
				</li>}
			</ul>
		</div>
	)
}

const MediaCardContent: React.FC<IContent> = ({ affair }) => {
	const { source, lead, creator } = affair;

	return (
		<div className="content_affair">
			<ul className="list-group affair_description">
				{lead && <li className="list-group-item">
					<p>{lead}</p>
				</li>}
				<li className="list-group-item">
					<p className="bold">Quelle:</p>
					<p className="">{source === 'Argus' ? creator : source}</p>
				</li>
			</ul>
		</div>
	)
}

const FeedlyCardContent: React.FC<IContent> = ({ affair }) => {
	const { source, creator } = affair;

	return (
		<div className="content_affair">
			<ul className="list-group affair_description">
				{source && <li className="list-group-item">
					<p className="bold">Quelle:</p>
					<p className="">{source}</p>
				</li>}
				{creator && <li className="list-group-item">
					<p className="bold">Urheber:</p>
					<p>{creator}</p>
				</li>}
			</ul>
		</div>
	)
}

export {
	PolitCardContent,
	MediaCardContent,
	FeedlyCardContent,
}