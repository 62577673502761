import React from 'react';
import { observer } from 'mobx-react';
import { IAffairCard } from '../../interface/affair';
import Icon from '../../../../shared/Icon';
import ModalStore from '../../../../shared/Modal/ModalStore';
import clientStore from '../../store/ClientStore';
import {
	PolitCardContent,
	MediaCardContent,
	FeedlyCardContent,
} from './CardsContent';

import userStore from '../../../../stores/UserStore';
import { url } from '../../../../services/http/config';
import PrioritySwitcher from '../MultiFilter/PrioritySwitcher';
import { convertToObject } from 'typescript';

interface IPropsType {
	affair: IAffairCard;
	filteredIssue: string;
	isDeleted?: boolean;
	index: number;
}

interface IDocType {
	[key: string]: Array<string>
}

const AffairCard: React.FC<IPropsType> = observer(({ affair, filteredIssue, isDeleted, index }) => {

	const mediaLink = `${url}${affair.link}?auth_token=${userStore.token
		}`;

	const values: IDocType = {
		political: ['polit-affair', 'affair', 'polit'],
		media: ['media-affair', 'media', 'media'],
		[`feedly-doc`]: ['feedly-doc-affair', 'feedly-doc', 'feedly']
	}

	const { docType, bucket_id, id, _id, title, source, keywords } = affair;
	const classValues = values[docType][0];
	const typesForPost = values[docType][1];
	const cardTypeValues = values[docType][2];
	const cardType = docType === `feedly-doc` ? 'feedly-doc' : cardTypeValues;

	const clickHandler = async (e: any) => {
		clientStore.checkedAffair = affair;
		clientStore.checked = true;
		clientStore.isMobileButtonExit = true;
		await clientStore.setCurrentAffair(id ? id : _id, typesForPost, keywords);
	}
	return (
		<div className={`content-container ${classValues}`}>
			<div className="affair_block">
				<a href={docType === 'media' ? source === 'Argus' ? affair.link : mediaLink : affair.link}
					target="_blank"
					rel="noopener noreferrer">
					<h5>
						{title}
					</h5>
				</a>
			</div>

			<div className={`affair_item ${clientStore.currentView === "affairs"
				? (clientStore.affairs[index] === clientStore.checkedAffair ? 'checked' : '')
				: (clientStore.bucket[index] === clientStore.checkedAffair ? 'checked' : '')}`} onClick={clickHandler} >
				<div>
					{docType === 'political' && <PolitCardContent affair={affair} />}
					{docType === 'media' && <MediaCardContent affair={affair} />}
					{docType === `feedly-doc` && <FeedlyCardContent affair={affair} />}
				</div>
				<div className='icon-container'>
					{clientStore.currentView === "affairs" && <PrioritySwitcher index={index} affair={affair} type={typesForPost} />}
					{
						isDeleted ?
							<Icon iconType='refresh' onClick={ModalStore.openModal('restore', { id: bucket_id, target_id: id ? id : _id })} />
							:
							<Icon iconType='garbage' onClick={ModalStore.openModal('remove', { target_id: id ? id : _id, type: cardType })} />
					}
				</div>
			</div>

		</div>
	);
});


export default AffairCard;