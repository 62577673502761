import { observable, action } from 'mobx';
import validator from '../../../validator';
import CatalogServices from '../../../services/CatalogServices';
import { Iform, IFormState } from '../interface';

const defaultForm = {
	email: '',
	password: '',
};

const defaultFormState = {
	errorFields: {
		email: '',
		password: '',
	},
	success: false,
	isPasswordShow: false,
	passwordType: 'password',
	token: '',
	successType: 'request',
	component: 'ForgotPasswordRequest',
};

class ForgotPassForm {
	@observable form: Iform = { ...defaultForm };

	@observable formState: IFormState = { ...defaultFormState };

	@action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		this.form[name] = value;

		if (this.formState.errorFields.email) {
			this.formState.errorFields.email = '';
		}
	};

	@action resetPassRequest = () => {
		const emailValidated = validator.validateField('email', this.form.email);
		if (emailValidated.isFieldValid) {
			CatalogServices.postResetPasswordRequest(this.form.email).then(
				response => {
					this.formState.component = 'RequestSuccess';
					this.formState.successType = 'request';
					setTimeout(() => (this.formState.success = true), 500);
				},
				error => {
					this.formState.errorFields.email = error.response.data.message;
				}
			);
		} else {
			this.formState.errorFields.email = emailValidated.errorMessage;
		}
	};

	@action resetPass = () => {
		const passwordValidated = validator.validateField(
			'password',
			this.form.password
		);
		if (passwordValidated.isFieldValid) {
			CatalogServices.postResetPassword({
				password: this.form.password,
				[`password-reset-token`]: this.formState.token,
			}).then(response => {
				this.formState.component = 'RequestSuccess';
				this.formState.successType = 'reset';
				setTimeout(() => (this.formState.success = true), 500);
			});
		} else {
			this.formState.errorFields.password = passwordValidated.errorMessage;
		}
	};

	@action setComponent = (componentName: string) => {
		this.formState.component = componentName;
	};

	@action setToken = (token: any) => {
		this.formState.token = token;
	};

	@action resetStore = () => {
		this.form = { ...defaultForm };
		this.formState = { ...defaultFormState };
	};

	@action toggleTypePassword = () => {
		this.formState.isPasswordShow = !this.formState.isPasswordShow;
		this.formState.passwordType = this.formState.isPasswordShow
			? 'text'
			: 'password';
	};
}

const forgotPassForm = new ForgotPassForm();
export default forgotPassForm;
