interface IDefaultLiterals {
	empty: string;
}

interface ILiteralsEmails {
	empty: string;
	invalid: string;
}

interface Iusername {
	empty: string;
	invalid: string;
}

interface ILiterals {
	[key: string]: any;
	title: IDefaultLiterals;
	keyWord: IDefaultLiterals;
	source: IDefaultLiterals;
	feedly: IDefaultLiterals;
	issue: IDefaultLiterals;
	sourceField: IDefaultLiterals;
	file: IDefaultLiterals;
	email: ILiteralsEmails;
	password: IDefaultLiterals;
	short: string;
	long: string;
	username: Iusername;
}

export const literals: ILiterals = {
	title: {
		empty: 'Bitte geben Sie Titel ein',
	},
	keyWord: {
		empty: 'Bitte geben Sie Schlüsselwort ein',
	},
	source: {
		empty: 'Bitte wählen Sie Quelle aus',
	},
	feedly: {
		empty: 'Bitte wählen Sie Feedly Board',
	},
	issue: {
		empty: 'Bitte wählen Sie Issue aus',
	},
	sourceField: {
		empty: 'Bitte geben Sie Quelle ein',
	},
	file: {
		empty: 'Datei nicht gefunden',
	},
	email: {
		empty: 'Bitte geben Sie Email ein',
		invalid: 'Email ungültig',
	},
	password: {
		empty: 'Bitte geben Sie Passwort ein',
	},
	username: {
		empty: 'Bitte geben Sie Kundenname ein',
		invalid: 'Es wurden unerlaubte Zeichen verwendet',
	},
	short: 'Wert soll höher als',
	long: 'Wert soll niedriger als',
};
