import React, { Component } from 'react';
import Input from '../../shared/Input';
import loginForm from './stores/LoginForm';
import { observer } from 'mobx-react';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '../../shared/Button';
import userStore from '../../stores/UserStore';
import InputPassword from '../../shared/Input/InputPassword';
// import { isMobileDevice } from 'react-select/lib/utils';
import { develop } from '../../services/http/config';
// import BACKEND_LOGO from '../../assets/img/1.jpg';

interface IPropsType {
	isAllAffairs: boolean;
}

type PropsType = RouteComponentProps & IPropsType;

@observer
class Login extends Component<PropsType> {
	componentDidMount() {
		userStore.checkIsMobileVersion();
	}
	render() {
		const passwordType = loginForm.isPasswordShow ? 'text' : 'password';
		const { isHidden, isMobile, toggleMenu } = userStore;
		const animationClass = isHidden ? 'close_login' : 'open_login';
		return (
			<div className="main-container">
				<div
					className="overlay"
					style={{ display: isMobile && !isHidden ? 'block' : 'none' }}
					onClick={toggleMenu}
				></div>
				<form
					className={`login-container ${animationClass}`}
					onSubmit={(event) => loginForm.login(event, this.props.location.search)}
				>
					{userStore.token ? <Redirect to="/" /> : null}
					<div className="width-p-100 w-m-250">
						<Input
							value={loginForm.form.email}
							onChange={loginForm.onChange}
							placeholder="Email"
							name="email"
							type="text"
						/>
						<InputPassword
							name="password"
							type={passwordType}
							value={loginForm.form.password}
							placeholder="Passwort"
							onChange={loginForm.onChange}
							autocomplete="current-password"
							isPasswordShow={loginForm.isPasswordShow}
							toggleTypePassword={loginForm.toggleTypePassword}
						/>
						<Button text="Login" type="submit" className="btn btn-secondary" />
						<Link to="/forgot-password">
							<span className="forget-password">Passwort vergessen?</span>
						</Link>
					</div>
				</form>
				<div className="greeting-container">
					<div className="greeting">
						{!develop.production ? (
							<div className="content_control">
								<div>
									<span>Politik- &#38;</span>
									<span>Medienmonitoring 2.0</span>
								</div>
								<Button
									text="Login"
									type="submit"
									className="btn btn-secondary loginBtn"
									onClick={toggleMenu}
								/>
							</div>
						) : (
							<>
								<h1>
									{'Politik- & Medienmonitoring'}
								</h1>
								<p>
									Zeit ist nicht nur Geld, Zeit ist auch politisches sowie
									wirtschaftliches Kapital. Dank einem professionellen
									Issues-Management-System sind wir in der Lage, Ihre Themen in
									der Politik und in der medialen Öffentlichkeit rund um die Uhr
									zu beobachten und Ihnen entscheidende Informationen zeitnah
									zur Verfügung zu stellen. Unsere Unterstützung ermöglicht
									Ihnen, zum jeweils frühest möglichen Zeitpunkt politische und
									kommunikative Massnahmen zu ergreifen – und damit immer den
									entscheidenden Schritt voraus zu sein.
								</p>
								<p>
									Gerne beraten wir Sie über unsere massgeschneiderten Angebote.
									Wir freuen uns auf Ihre Kontaktaufnahme:
								</p>
								<p>
									IWF AG <br></br> c/o Haus der Wirtschaft<br></br>{' '}
									Hardstrasse 1 <br></br>CH-4133 Pratteln
									<br></br> +41 (0)61 927 65 86
									<br></br>
									<a href="mailto:monitoring@iwf.ch">monitoring@iwf.ch</a>
								</p>
							</>
							// <div className="login-main-block" style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
							// 	<img style={{width:"50%", height:"50%", marginRight:"1%"}}  src={BACKEND_LOGO}></img>
							// 	<span style={{width: '49%', fontSize: '1rem', height:'50%'}}>Zeit ist nicht nur Geld, Zeit ist auch politisches sowie wirtschaftliches Kapital. Dank einem professionellen Issues-Management-System sind wir in der Lage, Ihre Themen in der Politik und in der medialen Öffentlichkeit rund um die Uhr zu beobachten und Ihnen entscheidende Informationen zeitnah zur Verfügung zu stellen. Unsere Unterstützung ermöglicht Ihnen, zum jeweils frühest möglichen Zeitpunkt politische und kommunikative Massnahmen zu ergreifen – und damit immer den entscheidenden Schritt voraus zu sein.</span>
							// </div>
						)}
					</div>
					<div style={{ paddingBottom: '25px', display: 'flex', width: '100%', justifyContent: 'center' }}>
						<Button
							text="Login"
							type="submit"
							className="btn btn-secondary loginBtn"
							onClick={toggleMenu}
						/>
					</div>

					<br></br>
					<br></br>
				</div>
			</div>
		);
	}
}

export default withRouter(Login);
