import React from 'react';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input/index';
import forgotPassForm from '.././stores/ForgotPassForm';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

const ForgotPasswordRequest = observer(() => {
	return (
		<div className="login-container forgot" id="forgot-password-form">
			<div className="width-p-100 w-m-250">
				<h4 className="mb-3">Passwort vergessen?</h4>
				<figure className="mb-4">
					Bitte geben Sie Ihre hinterlegte Email-Adresse ein, um Ihr Passwort zurückzusetzen.
				</figure>
				<Input
					value={forgotPassForm.form.email}
					onChange={forgotPassForm.onChange}
					errorOnField={forgotPassForm.formState.errorFields.email}
					placeholder="Email"
					name="email"
					type="text"
				/>
				<Button
					text="Link anfordern"
					onClick={forgotPassForm.resetPassRequest}
					className="btn btn-secondary"
				/>
				<figure>
					<Link to="/login">
						<span className="forget-password">Zurück zur Anmeldung</span> </Link>
				</figure>
			</div>
		</div>
	);
});

export default ForgotPasswordRequest;