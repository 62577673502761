import React, { Fragment } from 'react';
import { IDocument } from '../interface/issue';
import userStore from '../../../stores/UserStore';
import { url } from '../../../services/http/config';

interface IProps {
	documents: Array<IDocument>;
}

const DocumentsList: React.FC<IProps> = ({ documents }) => {
	return (
		<Fragment>
			{documents.map((document, index) => {
				const docLink = `${url}${document.link}?auth_token=${userStore.token}`;
				return document.details.docs.map((doc, key) => (
					<li key={`doc.title-${key}`}>
						<a href={docLink} target="_blank" rel="noopener noreferrer">
							{index + 1}.{doc.title}
						</a>
					</li>
				));
			})}
		</Fragment>
	);
};

export default DocumentsList;
