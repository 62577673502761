import { ReactElement } from 'react';
import ReactDOM from 'react-dom';

export default class Helper {
	static showOverlay(id: string = 'overlay', component: ReactElement) {
		if (document.getElementById(id)) return;
		const overlay = document.createElement('section');
		overlay.setAttribute('id', id);
		document.body.appendChild(overlay);
		document.body.setAttribute('class', 'no-scroll');

		ReactDOM.render(component, overlay);
	}

	static removeOverlay(id: string) {
		const overlay = document.getElementById(id);

		document.body.setAttribute('class', '');
		if (overlay) {
			ReactDOM.unmountComponentAtNode(overlay);
			document.body.removeChild(overlay);
		}
	}

	static normalizeDate(timestamp: number | undefined):string {
		return timestamp ? new Date(timestamp).toLocaleDateString('en-GB')
															.split('/')
															.join('.')
										: ''
	}
}
