import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import { agencyStore, agencyClientFormStore } from '../store/index';
import InputFile from '../../../shared/Input/InputFile';

type ClientOverlayProps = {
	id: string;
	isEditOverlay: boolean;
};

@observer
class ClientOverlay extends Component<ClientOverlayProps> {
	componentDidMount() {
		agencyClientFormStore.setIsEditOverlay(this.props.isEditOverlay);
	}

	componentWillUnmount() {
		agencyClientFormStore.resetStore();
	}

	disableSubmit = () => {
		const { username } = agencyClientFormStore.clientInfo;
		const { errorFields } = agencyClientFormStore.formState;
		let foundError: boolean = false
		for (const error in errorFields) {
			if (!!errorFields[error].length) {
				foundError = true
				break
			}
		}

		return !username || foundError
	}

	render() {
		const { username, newsFeed } = agencyClientFormStore.clientInfo;
		const { errorFields } = agencyClientFormStore.formState;

		const {
			onChange,
			onCheck,
			onFormSubmit,
		} = agencyClientFormStore;
		const titleOverlay = this.props.isEditOverlay
			? 'Speichern'
			: 'Neuen Kunden anlegen';

		return (
			<div className="clientOverlay">
				<button
					className="closeOverlay_btn"
					onClick={() => agencyStore.removeOverlay(this.props.id)}
				>
					Abbrechen
				</button>
				<form className="createClientOverlay_form" onSubmit={onFormSubmit}>
					<Input
						name="username"
						type="text"
						value={username}
						placeholder="Kundenname"
						errorOnField={errorFields.username}
						onChange={onChange}
					/>
					<InputFile
						onChange={onChange}
						placeholder="Logo hochladen"
						name="logo"
						value={agencyClientFormStore.logo.value}
						accept=".jpg, .jpeg, .png"
					/>
					<div className='createClientOverlay_form_news-feed_checkbox'>
						<label>
							<input type="checkbox"
								checked={newsFeed}
								onChange={onCheck}
								name="newsFeed"
							/>
							{" "}API News Feed
						</label>
					</div>

					<div className="createClientOverlay_form__btn-group">
						<Button
							text={titleOverlay}
							className="btn btn-outline-success"
							type="submit"
							disabled={this.disableSubmit()}
						/>
						<Button
							text="Abbrechen"
							onClick={() => agencyStore.removeOverlay(this.props.id)}
							className="btn btn-outline-danger"
						/>
					</div>
				</form>
			</div>
		);
	}
}

export default ClientOverlay;
