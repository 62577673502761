import { IaffairDocs } from '../../interface/affair';

const sortByDocDate = (data: Array<object>): Array<object> => {
	const result = JSON.parse(JSON.stringify(data));
	const createNumberFromDate = (str: string): number =>
		Number(
			str
				.split('.')
				.reverse()
				.join('')
		);

	result.sort((firstItem: IaffairDocs, secondItem: IaffairDocs) => {
		const firstNum = firstItem.date
			? createNumberFromDate(firstItem.date)
			: firstItem.from
			? createNumberFromDate(firstItem.from)
			: 0;
		const secondNum = secondItem.date
			? createNumberFromDate(secondItem.date)
			: secondItem.from
			? createNumberFromDate(secondItem.from)
			: 0;

		return secondNum - firstNum;
	});

	return result;
};

export default sortByDocDate;
