import React from 'react';
import MainRouter from './MainRouter';
import { version } from '../package.json';

// window.onpopstate = function(event) {
//   // alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
//   console.log('-------------------------------------------------',Number(storageService.getItem(STORAGE_CONST.SCROLL_POS)));
//   window.scrollTo({
//     top: Number(storageService.getItem(STORAGE_CONST.SCROLL_POS))
//   })
// };

const App: React.FC = () => {
	console.log('Developed by %c ', 'font-size: 1px; padding: 8px 20px; line-height: 0; background: url("https://mindset.swiss/wp-content/uploads/2020/12/mindset_logo.png"); background-size: contain; background-repeat: no-repeat; color: transparent;');
	console.log('https://mindset.swiss/')
	console.log(`Version: %s`, version);
	return <MainRouter />;
};

export default App;
