import { observable, action, reaction } from 'mobx';
import { Iissue } from '../interface/issue';
import CatalogServices from '../../../services/CatalogServices';
import userStore from '../../../stores/UserStore';
import { Iaffair, IbucketAffair, IAffairCard } from '../interface/affair';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { Ipagination } from '../interface/paginstion';
import { uniqBy } from 'lodash';
import {
	storageService,
	STORAGE_CONST,
} from '../../../services/StorageService';
import { v4 as uuidv4 } from 'uuid';

const defaultAffair = {
	details: {
		docs: [],
	},
	parsedDetails: {
		businessNumber: '',
		creator: '',
		title: '',
		type: '',
		status: '',
		pendingWith: '',
	},
	creationDate: '',
	_id: '',
	link: '',
	source: '',
	highlighting: [],
	keywords: [],
	docType: 'political',
	lastUpdateDate: 0,
	lead: '',
	priority: '',
	status: '',
	comment: [],
	watchList: [],
	review: '',
};

const defaultPagination = {
	start: 0,
	rows: 25,
	totalCount: 0,
	hasMore: true,
};

class ClientStore {
	@observable issues: Array<Iissue> = [];
	@observable currentAffair: Iaffair = { ...defaultAffair };
	@observable listState: any = false;
	@observable currentView: string = 'affairs';
	@observable checkedIssue: Array<string> = [];
	@observable affairs: Array<IAffairCard> = [];
	@observable pagination: Ipagination = { ...defaultPagination };
	@observable isLoading: boolean = false;
	@observable watchListLoading: boolean = false;
	@observable checkedAffair: any = null;
	@observable checked: boolean = false;
	@observable isMobileButtonExit: boolean = false;
	@observable bucket: Array<IAffairCard> = [];
	@observable isOpenMultiFilter: boolean = false;
	@observable isActiveMultiFilter: boolean = false;
	@observable searchInputValue: any = '';
	@observable priorityFilter: any = [];
	@observable positionGeneralList: number = 0;
	@observable startDate: any = 0;
	@observable endDate: any = new Date();
	@observable politSources: Array<any> = [];
	@observable mediaSources: Array<any> = [];
	@observable argusSources: Array<any> = [];
	@observable solrDocs: Array<any> = [];
	@observable selectedArgusLeads: Array<any> = [];
	@observable selectedPolitSources: Array<any> = [];
	@observable selectedMediaSources: Array<any> = [];
	@observable isAllIssuesChecked: boolean = true || false;
	@observable facet_counts: any;
	@observable commentForm: any = {
		_id: uuidv4(),
		user: userStore.user && userStore.user._id, 	// user id
		email: '',
		comment: '',
		creationDate: Date.now()
	};
	@observable review: string = '';
	@observable commentPostSuccess: boolean = false

	@action changeView = () => {
		this.currentView = this.currentView === 'affairs' ? 'bucket' : 'affairs';
		this.checkedAffair = null;
	};

	@action addPriority = (
		type: string,
		target_id: string,
		value: any,
		index: number
	) => async () => {
		const response = await CatalogServices.postCreatePriority(type, target_id, value);

		if (response.statusText === "Created") {
			this.affairs[index].priority = value;
		}
	}

	@action deleteAffair = (config: {
		type: string;
		target_id: string;
	}) => async () => {
		const response = await CatalogServices.moveToBucket(config);

		if (response.statusText === 'OK') {
			this.affairs = this.affairs.filter(
				affair => affair.id !== config.target_id
			);
			// this.getBucket();
		}
	};

	@action restoreAffair = (config: { id: string, target_id: string }) => async () => {
		const response = await CatalogServices.restoreAffair(config.id, config.target_id);
		if (response.statusText === 'OK') {
			this.bucket = this.bucket.filter(
				(affair: IAffairCard) => affair.bucket_id !== config.id
			);
			this.affairs = [];
			this.setFilteredAffairs(true, true);
		}
	};

	@action getBucket = async () => {
		const token =
			userStore.token && storageService.getItem(STORAGE_CONST.TOKEN);
		const userId = userStore.user
			? userStore.user._id
			: storageService.getItem(STORAGE_CONST.USER)._id;

		if (token && userId) {
			let { data } = await CatalogServices.getBucket(userId);
			data = data.map((item: IbucketAffair) => {
				const additionalData = {
					bucket_id: item._id,
					docType: (item.type === 'polit' || item.type === 'affair') ? 'political' : item.type,
				};
				let currentAffair: Iaffair = { ...defaultAffair };
				const keys = ['feedlyDoc', 'media', 'affair'];
				keys.forEach((key: string) => {
					if (item[key].length) {
						currentAffair = Object.assign(currentAffair, item[key][0]);
					}
				});
				currentAffair.link = this.changeAffairLink(
					currentAffair.source,
					currentAffair.link
				);
				currentAffair.source = this.changeAffairSource(
					currentAffair.source,
					currentAffair.link
				);
				return { ...currentAffair, ...additionalData };
			});

			this.bucket = data;
		}
	};

	@action getSources = async () => {
		this.isLoading = true;
		const { data } = await CatalogServices.getFilterSources();
		this.politSources = data.affair.map((source: any) => {
			return {
				id: source._id,
				label: source.title,
				disabled: false
			}
		}).filter((source: any) => source.id.replace(/\s/g, '').length > 0 && source.id !== 'feedly' && source.id !== 'argus')

		const feedlySources = data.feedly.map((source: any) => {
			return {
				id: source._id,
				label: source.title,
				disabled: false
			}
		}).filter((source: any) => source.id.replace(/\s/g, '').length > 0)

		const mediaSources = data.media.map((source: any) => {
			return {
				id: source._id,
				label: source.title,
				disabled: false
			}

		}).filter((source: any) => source.id.replace(/\s/g, '').length > 0)

		const argusSources = data.argus.map((source: any) => {
			return {
				id: source._id,
				label: source.title,
				disabled: false
			}

		}).filter((source: any) => source.id.replace(/\s/g, '').length > 0)
		this.mediaSources = [...feedlySources, ...mediaSources];
		this.argusSources = argusSources
		this.isLoading = false;
		this.selectedPolitSources = this.politSources;
		this.selectedMediaSources = this.mediaSources.filter((source) => source.disabled === false);
		this.selectedArgusLeads = this.argusSources.filter((source) => source.disabled === false);

	}

	@action getUserIssues = async (dashboardFlag: Boolean = false, facetField?: any) => {
		if (userStore.user === null) return;
		const storedCheckedIssues = dashboardFlag
			? localStorage.getItem('storedCheckedIssuesDashboard')
			: localStorage.getItem('storedCheckedIssues')
		if (storedCheckedIssues) {
			this.issues = JSON.parse(storedCheckedIssues)
			if (this.issues.filter(issue => issue.isChecked).length > 0) {

			} else {
				this.isAllIssuesChecked = false
			}
		} else {
			const { data: issues } = await CatalogServices.getUserIssues(
				userStore.user._id
			);

			this.issues = issues.map((issue: Iissue) => {
				return { ...issue, isChecked: true };
			});
		}
		this.handelChanger('', dashboardFlag, facetField)
	};

	@action allIssuesChecked = async (dashboardFlag: Boolean = false, facetField?: any) => {

		this.isAllIssuesChecked = !this.isAllIssuesChecked;
		this.issues = this.issues.map((issue: Iissue) => {
			return { ...issue, isChecked: this.isAllIssuesChecked };
		});

		if (dashboardFlag) {
			localStorage.setItem('storedCheckedIssuesDashboard', `${JSON.stringify(this.issues)}`)
		} else {
			localStorage.setItem('storedCheckedIssues', `${JSON.stringify(this.issues)}`)
		}

		if (this.isAllIssuesChecked) {
			this.checkedIssue = this.issues.map(issue => issue._id);

		} else {
			this.checkedIssue = [];
			this.pagination.totalCount = defaultPagination.totalCount;
		};
		clientStore.setFilteredAffairs(false, false, true, true, dashboardFlag, facetField);
	}

	@action handelChanger = async (
		id?: string,
		dashboardFlag: Boolean = false,
		facetField?: any,
	) => {

		if (this.isAllIssuesChecked) {
			this.checkedIssue = this.issues.map(issue => issue._id);
			this.isAllIssuesChecked = false;
		}

		this.issues = this.issues.map(issue => {
			if (issue._id === id) {
				return { ...issue, isChecked: !issue.isChecked };
			}
			return issue;
		});
		this.checkedIssue = clientStore.issues.filter(issue => issue.isChecked).map(issue => issue._id);
		if (dashboardFlag) {
			localStorage.setItem('storedCheckedIssuesDashboard', `${JSON.stringify(this.issues)}`)
		} else {
			localStorage.setItem('storedCheckedIssues', `${JSON.stringify(this.issues)}`)
		}
		if (!this.checkedIssue.length) {
			this.pagination.totalCount = defaultPagination.totalCount;
		}

		if (this.checkedIssue.length === this.issues.length) {
			this.isAllIssuesChecked = true;

			// this.issues = this.issues.map((issue: Iissue) => {
			// 	return { ...issue, isChecked: false };
			// });
		}
		this.positionGeneralList = 0;

		clientStore.setFilteredAffairs(false, false, true, true, dashboardFlag, facetField);
	};

	private changeAffairSource(source: string, link: string | null) {
		let result = '';
		if (link === null) {
			return result;
		}
		if (source === 'parlament') {
			result = 'Bund';
			return result;
		}
		if (source === 'grosserrat') {
			result = 'Baselstadt';
			return result;
		}
		if (source === 'feedly') {
			const splittedData = link.split('/');
			result = link.includes('http') ? splittedData[2].toLowerCase() : ''; // GET BASE URL FROM FEEDLY ORIGIN URL
		} else {
			result = source.match(/www/) ? source : source.charAt(0).toUpperCase() + source.slice(1);
		}
		return result;
	}

	private changeAffairLink(source: string, link: string) {
		const NEW_PARLAMENT_LINK =
			'https://www.parlament.ch/de/ratsbetrieb/suche-curia-vista/geschaeft?AffairId=';
		if (source === 'parlament' && link && link.match(/http/)) {
			const affairId = link.match(/[0-9].*/);
			return NEW_PARLAMENT_LINK + affairId;
		}
		return link;
	}

	@action setCurrentAffair = async (
		affairId: string,
		affairType: string,
		keywords: Array<Array<string>>,
	) => {

		this.isLoading = true;
		const { data } = await CatalogServices.getAffair(
			affairId,
			affairType,
			keywords
		);
		const affair = data;
		this.review = data.review ? data.review : ''
		affair.details.docs && affair.details.docs.forEach((doc: any) => {
			if (doc.keywords) {
				doc.keywords.forEach((keyword: any) => {
					keywords.push(keyword);
				});
			}
		});

		affair.link = this.changeAffairLink(affair.source, affair.link);
		affair.source = this.changeAffairSource(affair.source, affair.link);

		if (
			affair.parsedDetails.creator &&
			affair.parsedDetails.creator.includes(',')
		) {
			if (affair.parsedDetails.creator.includes(',')) {
				affair.parsedDetails.creator = affair.parsedDetails.creator.replace(
					',',
					''
				);
			}
		}

		const typeValue = affairType === `affair` ? 'political' : affairType;

		const keyWordsSet = new Set(keywords);
		const upgradedAffair = { ...affair, keywords: Array.from(keyWordsSet), docType: typeValue };

		this.isLoading = false;
		this.currentAffair = upgradedAffair;
	};

	@action setFilteredAffairs = async (
		isPaginated: boolean,
		withoutLoading?: boolean,
		updateFilters?: boolean,
		resetFilters?: boolean,
		dashboardFlag: Boolean = false,
		facetField?: any,
		exportFlag: Boolean = false,
	) => {
		if (!isPaginated) {
			this.pagination = defaultPagination;
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
		if (!withoutLoading) {
			this.isLoading = true;
		}
		const politSourcesId = this.selectedPolitSources.map(({ id }) => id)
		const mediaSourcesId = this.selectedMediaSources.map(({ id }) => id)
		const argusLeads = this.selectedArgusLeads.map(({ id }) => id)
		let checkedIssue
		if (this.checkedIssue[0]) {
			checkedIssue = this.checkedIssue

		} else {
			const storedCheckedIssues = dashboardFlag
				? JSON.parse(`${localStorage.getItem('storedCheckedIssuesDashboard')}`)
				: JSON.parse(`${localStorage.getItem('storedCheckedIssues')}`)
			if (storedCheckedIssues) {
				checkedIssue = storedCheckedIssues.filter((e: any) => e.isChecked).map((e: any) => e._id)

			} else {
				checkedIssue = []
			}
		}
		this.facet_counts = null
		// check if it is for dashboard or issue list
		let { data } = await CatalogServices.getFilteredAffairs(
			checkedIssue,
			this.pagination,
			this.searchInputValue,
			this.priorityFilter,
			this.startDate,
			this.endDate,
			politSourcesId,
			mediaSourcesId,
			argusLeads,
			updateFilters,
			resetFilters,
			dashboardFlag,
			facetField,
			exportFlag,
		);
		// this.getBucket();
		if (dashboardFlag) {
			let labels: any
			let tempData: any = {}
			// var colors = ['#003F5C', '#2F4B7C', '#665191', '#A05195', '#D45087', '#F95D6A', '#FF7C43', '#FFA600', '#F64040', '#D95419', '#B76200', '#956A00', '#736E00', '#536E00', '#326B1C', '#006631']
			let colors: any
			if (facetField === 'source_txt') {
				colors = ['#006631', '#104d66']
			} else {
				colors = ['red', 'yellow', 'green']
			}
			data.solrResponse.forEach((e: any, i: number) => {
				const metric = e.result.facet_counts.facet_fields[facetField]
				for (var j = 0; j < metric.length && metric[j + 1] > 0; j++) {
					if (facetField === 'source_txt') {
						if (!tempData['Medien-Berichte']) {
							tempData['Medien-Berichte'] = {}
							tempData['politische Geschäfte'] = {}
							data.solrResponse.forEach(({ title }: any) => {
								tempData['Medien-Berichte'][title] = 0
								tempData['politische Geschäfte'][title] = 0
							});
						}
						if (
							metric[j] === 'grosserrat'
							|| metric[j] === 'baselland'
							|| metric[j] === 'aargau'
							|| metric[j] === 'parlament'
							|| metric[j] === 'solothurn'
						) {
							tempData['politische Geschäfte'][e.title] = tempData['politische Geschäfte'][e.title] + metric[j + 1]
						} else {
							tempData['Medien-Berichte'][e.title] = tempData['Medien-Berichte'][e.title] + metric[j + 1]
						}
						j++
					} else {
						if (!tempData['Hoch']) {
							tempData['Hoch'] = {}
							tempData['Mittel'] = {}
							tempData['Tief'] = {}
							data.solrResponse.forEach(({ title }: any) => {
								tempData['Hoch'][title] = 0
								tempData['Mittel'][title] = 0
								tempData['Tief'][title] = 0
							});

						}
						if (metric[j] === 'high') tempData['Hoch'][e.title] = tempData['Hoch'][e.title] + metric[j + 1]
						if (metric[j] === 'medium') tempData['Mittel'][e.title] = tempData['Mittel'][e.title] + metric[j + 1]
						if (metric[j] === 'low') tempData['Tief'][e.title] = tempData['Tief'][e.title] + metric[j + 1]
						j++
					}

				}
			});

			const datasets = Object.keys(tempData).map((data: any, i: number) => {

				let backgroundColor = colors[i]
				if (!labels) labels = Object.keys(tempData[data])
				return {
					label: data,
					data: Object.values(tempData[data]),
					backgroundColor
				}
			})
			console.log({
				labels,
				datasets
			})
			this.facet_counts = {
				labels,
				datasets
			}
		} else if (exportFlag) {
			const XLSX = require('xlsx');
			const ws = XLSX.utils.json_to_sheet(data.solrResponse)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, ws, 'Responses')
			XLSX.writeFile(wb, `Affairs ${new Date().toLocaleDateString()}.xlsx`)

			this.isLoading = false;
			this.affairs = [...this.affairs]
		}
		else {
			if (updateFilters && data.argus) {
				const argusSources = data.argus.map((source: any) => {
					return {
						id: source._id,
						label: source.title,
						disabled: false
					}

				}).filter((source: any) => source.id.replace(/\s/g, '').length > 0)
				this.argusSources = argusSources
				this.selectedArgusLeads = this.argusSources.filter((source) => source.disabled === false);
			}
			this.pagination.totalCount = data.totalCount;
			var allAffairs = data.solrResponse.sort((first: any, second: any) => +new Date(second.lastUpdateDate) - +new Date(first.lastUpdateDate));
			this.solrDocs = data.solrDocs ? data.solrDocs : {}
			if (isPaginated) {
				this.isLoading = false;
				allAffairs = uniqBy([...this.affairs, ...allAffairs], 'id');
				this.affairs = allAffairs ? allAffairs : [];
				if (!withoutLoading) {
					this.isLoading = false;
				}
				return;
			}
			this.isLoading = false;

			this.affairs = allAffairs ? allAffairs : [];
			if (!withoutLoading) {
				this.isLoading = false;
			}
			this.isOpenMultiFilter = false;

		}
	};

	@action setFilteredIssue = (issueList: any) => {
		this.checkedIssue = issueList;
		this.issues = this.issues.map(issue => {
			const isChecked = this.checkedIssue.some(id => issue._id === id);
			return { ...issue, isChecked };
		});
	};

	@action handlePageClick = async (page: number) => {
		const start = (page - 1) * 25;
		if (start < this.pagination.totalCount) {
			this.pagination.start = (page - 1) * 25;
			this.pagination.hasMore = true;
		} else if (start >= this.pagination.totalCount) {
			this.pagination.start = 0;
			this.pagination.hasMore = false;
		}
		this.setFilteredAffairs(true);
	};

	@action closeAffair = () => {
		this.checked = false;
		this.isMobileButtonExit = false;
		this.checkedAffair = null;
	}

	@action commentOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		this.commentForm[name] = value;
	};

	@action commentFormHandler = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		var currentAffairID = this.currentAffair && this.currentAffair._id

		const commentData = {
			...this.commentForm,
			email: userStore.user && userStore.user.subEmail
				? userStore.user.subEmail
				: userStore.user && userStore.user.email,
			agency: userStore.user && userStore.user.agency,
			user_id: userStore.user && userStore.user.user_id,
		}

		try {
			const res = await CatalogServices.postComment({ commentData: commentData, docType: this.currentAffair.docType, title: this.currentAffair.parsedDetails.title }, currentAffairID);
			if (res.status == 200) {
				const commentData = this.currentAffair.comment ? [
					...this.currentAffair.comment,
					JSON.parse(res.config.data).commentData
				] : [JSON.parse(res.config.data).commentData]

				this.currentAffair = { ...this.currentAffair, comment: commentData };
				this.commentForm.user = userStore.user && userStore.user._id;
				this.commentForm.email = '';
				this.commentForm.comment = '';
				this.commentForm.creationDate = Date.now();
			}
		}
		catch (e) {
			console.error(e);
		}
	}

	@action addUserToWatchListHandler = async (data: any,) => {
		this.watchListLoading = true
		var currentAffairID = this.currentAffair && this.currentAffair._id
		try {
			const res = await CatalogServices.addUserToWatchList(data, currentAffairID);
			if (res.status == 200) {
				let watchList = this.currentAffair.watchList || []
				watchList.push(JSON.parse(res.config.data).user_id)
				this.watchListLoading = false
				this.currentAffair = { ...this.currentAffair, watchList };
			}
		}
		catch (e) {
			this.watchListLoading = false
			console.error(e);
		}
	}

	@action removeUserFromWatchListHandler = async (data: any,) => {
		this.watchListLoading = true
		var currentAffairID = this.currentAffair && this.currentAffair._id
		try {
			const res = await CatalogServices.removeUserfromWatchList(data, currentAffairID);
			if (res.status == 200) {
				const watchList = this.currentAffair.watchList ? this.currentAffair.watchList.filter(_id => _id != JSON.parse(res.config.data).user_id) : []
				this.watchListLoading = false
				this.currentAffair = { ...this.currentAffair, watchList };
			}
		}
		catch (e) {
			this.watchListLoading = false
			console.error(e);
		}
	}

	@action reviewChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { value, name } = event.target;
		this.review = value;
	};

	@action submitReview = async () => {
		var currentAffairID = this.currentAffair && this.currentAffair._id
		var docType = this.currentAffair.docType
		const res = await CatalogServices.postReview({ review: this.review, docType }, currentAffairID);
	}
}

const clientStore = new ClientStore();
// reaction(
// 	() => clientStore.checkedIssue.length,
// 	async length => {
// 		if (length) {
// 			// clientStore.getSources();
// 			clientStore.setFilteredAffairs(false, false, true);
// 		} else {
// 			clientStore.affairs = [];
// 		}
// 	}
// );
// reaction(
// 	() => clientStore.checkedIssue.length,
// 	() => {
// 		if (clientStore.checkedIssue.length === clientStore.issues.length) {
// 			clientStore.isAllIssuesChecked = true;
// 			clientStore.getUserIssues();
// 		} else {
// 			clientStore.isAllIssuesChecked = false;
// 		}
// 	}
// );
reaction(
	() => clientStore.searchInputValue.length,
	() => {
		clientStore.setFilteredAffairs(false);
	}, { delay: 500 }
);
reaction(
	() => clientStore.priorityFilter,
	() => {
		clientStore.setFilteredAffairs(false);
	}
);
export default clientStore;