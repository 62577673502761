import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import ClientList from './components/ClientList';
import ClientInfo from './components/ClientInfo';
import agencyStore from './store/AgencyStore';
import ClientOverlay from './overlays/ClientOverlay';
import CrawlerStatus from './components/CrawlerStatus';
import userStore from '../../stores/UserStore';

type IAgencyProps = {};

interface AgencyState {
	createClientOverlayId: string;
}
@observer
class Agency extends Component<IAgencyProps, AgencyState> {
	state: Readonly<AgencyState> = {
		createClientOverlayId: 'overlay',
	};

	showCreateClientOverlay = () => {
		const { createClientOverlayId } = this.state;
		agencyStore.showOverlay(
			createClientOverlayId,
			<ClientOverlay id={createClientOverlayId} isEditOverlay={false} />
		);
	};

	render() {
		const { isHidden, showMenu, translateStartSideBar, isMobile, } = userStore;
		return (
			<div className="content">
				<div className="overlay" style={{ display: isHidden ? 'none' : 'block' }} onClick={showMenu}></div>
				<section
					className="issues-list"
					style={{
						transform: isHidden ? translateStartSideBar : 'translateX(0)',
						transition: isMobile ? 'transform 1s' : 'transform 0s'
					}}
				>
					<aside>
						{/* <span className="client-page sing_out">
							<i onClick={userStore.logout} className="fas fa-sign-out-alt" />
						</span> */}
						<ClientList showCreateClientOverlay={this.showCreateClientOverlay} />
						<CrawlerStatus />
					</aside>
				</section>
				<main>
					<Route path="/client/:id" component={() => <ClientInfo />} />
				</main>
			</div>
		);
	}
}

export default Agency;
