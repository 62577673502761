import { ReactElement } from 'react';
import { observable, action } from 'mobx';
import Helper from '../../../helper';
import { Iissue, IFeedlyBoard } from '../interface/issue';
import CatalogServices from '../../../services/CatalogServices';
import { Ilogo, ICreateClient, ICreateUser } from '../interface/createClint';
import { IFilePDF } from '../interface/docInfo';
import userStore from '../../../stores/UserStore';

interface IclientInfo {
	username: string;
	_id: string;
	email: string;
	issues: Array<string>;
	password: string;
	role?: string;
	agency?: string;
	isDeleted?: boolean;
}

interface IuserInfo {
	_id: string;
	email: string;
	password: string;
	role?: string;
	agency?: string;
	isDeleted?: boolean;
	permissionLevel?: string;
}

const defaultCurrentClient = {
	username: '',
	_id: '',
	email: '',
	password: '',
	issues: [],
	agency: '',
	isDeleted: false,
};

class AgencyStore {
	@observable clientsInfo: Array<IclientInfo> = [];

	@observable currentClient: IclientInfo = { ...defaultCurrentClient };

	@observable currentClientIssues: Array<Iissue> = [];

	@observable currentClientUsers: Array<IuserInfo> = [];

	@observable activeTab: string = '';

	@observable crawlerStatus: any[] = [];

	@observable feedlyBoard: Array<IFeedlyBoard> = [];

	@action showOverlay = (elementId: string, element: ReactElement) => {
		Helper.showOverlay(elementId, element);
	};

	@action removeOverlay = (elementId: string) => {
		Helper.removeOverlay(elementId);
	};

	@action addClient = async (data: ICreateClient, logo: Ilogo) => {
		const role = 'client';
		const { data: client } = await CatalogServices.createClient({
			...data,
			role,
		});

		if (logo.value) {
			const formData = new FormData();
			formData.append('image', logo.file);

			await CatalogServices.postAddClientLogo(formData, client._id);
		}

		this.getAllClients();
	};

	@action addUser = async (data: ICreateUser, logo: Ilogo) => {
		const role = 'client';
		const id = this.currentClient._id
			? this.currentClient._id
			: userStore.user && userStore.user._id
				? userStore.user._id
				: ''
		const { data: client } = await CatalogServices.createSubUser({
			...data,
			role,
			client: id,
		});

		if (logo.value) {
			const formData = new FormData();
			formData.append('image', logo.file);

			await CatalogServices.postAddClientLogo(formData, client._id);
		}

		this.currentClient._id && this.getAllClients();
	};

	@action formatClients = (clients: Array<IclientInfo>) => {
		clients = clients.filter(client => {
			return client.role !== 'agencyAdmin';
		});

		return clients.sort((first, second) => {
			const { username: firstUsername } = first;
			const { username: secondUsername } = second;
			if (firstUsername.toLocaleLowerCase() < secondUsername.toLowerCase())
				return -1;
			if (firstUsername.toLocaleLowerCase() > secondUsername.toLowerCase())
				return 1;
			return 0;
		});
	};

	@action getAllClients = async () => {
		const { data } = await CatalogServices.getAllClients();
		this.clientsInfo = this.formatClients(data);
	};

	@action getCurrentClient = async (id: string) => {
		const { data } = await CatalogServices.getClient(id);
		this.currentClient = { ...data };
	};

	@action setDocsForCurrentIssue = () => {
		this.currentClientIssues = this.currentClientIssues.map(issue => {
			CatalogServices.getDocsForCurrentIssue(issue._id).then(
				({ data }) => (issue.documents = data)
			);
			return issue;
		});
	};

	private formatFeedly = (issues: Array<Iissue>) => {
		return issues.map((issue: Iissue) => {
			issue.filters.keywords.forEach(keyword => {
				if (keyword.feedlyTags) {
					keyword.title = 'Feedly';
					keyword.source = [];

					agencyStore.feedlyBoard.forEach(board => {
						// @ts-ignore
						keyword.feedlyTags.forEach(tag => {
							if (board._id === tag) keyword.source.push(board.label);
						});
					});
				}
			});
			return issue;
		});
	};

	@action getClientIssues = async (id: string) => {
		const { data } = await CatalogServices.getUserIssues(id);
		this.currentClientIssues = this.formatFeedly(data);
		this.setDocsForCurrentIssue();
	};

	@action getClientUsers = async (id: string) => {
		const { data } = await CatalogServices.getClientUsers(id);
		this.currentClientUsers = data;
	};

	@action getCrawlerStatusData = async () => {
		const { data } = await CatalogServices.getCrawlerStatus();
		this.crawlerStatus = data;
	};

	@action createNewIssue = async (issue: Iissue) => {
		const { data } = await CatalogServices.postCreateIssue(issue);
		const { currentClient } = this;

		currentClient.issues.push(data._id);
		await CatalogServices.assignedIssueToClient(
			currentClient._id,
			currentClient.issues
		);

		this.getClientIssues(this.currentClient._id);
	};

	@action uploadPDf = async (filePDF: IFilePDF) => {
		if (filePDF.value) {
			const formData = new FormData();
			formData.append('document', filePDF.file);
			formData.append('docInfo', JSON.stringify(filePDF.docInfo));

			await CatalogServices.postUploadPDF(formData);
		}
	};

	@action deleteIssue = async (issueId: string) => {
		await CatalogServices.deleteIssue(issueId);
		// this.getClientIssues(this.currentClient._id);
	};

	@action deleteSubUser = async (issueId: string) => {
		await CatalogServices.deleteSubUser(issueId);
		const id = this.currentClient._id
			? this.currentClient._id
			: userStore.user && userStore.user._id
				? userStore.user._id
				: ''
		if (id) this.getClientUsers(id);
	};

	@action editIssue = async (issue: Iissue, issueId: string) => {
		await CatalogServices.editIssue(issue, issueId);
		this.getClientIssues(this.currentClient._id);
	};

	@action editClient = async (
		client: { [key: string]: any },
		logo: Ilogo
	) => {
		await CatalogServices.putEditClient(this.currentClient._id, client);
		if (logo.value) {
			const formData = new FormData();
			formData.append('image', logo.file);

			await CatalogServices.postAddClientLogo(formData, this.currentClient._id);
		}
		this.getAllClients();
	};

	@action editClientUser = async (
		client: any,
		logo: Ilogo
	) => {
		await CatalogServices.putEditUser(client);
		if (logo.value) {
			const formData = new FormData();
			formData.append('image', logo.file);

			await CatalogServices.postAddClientLogo(formData, this.currentClient._id);
		}
		// this.getClientUsers(this.currentClient._id);
	};

	@action getFeedlyBoard = async () => {
		const { data } = await CatalogServices.getFeedlyTag();
		this.feedlyBoard = data;
	};

	@action setActiveTab = (id: string) => {
		this.activeTab = id;
	};
	@action resetStore = () => {
		this.clientsInfo = [];
		this.currentClient = defaultCurrentClient;
		this.currentClientIssues = [];
		this.activeTab = '';
	};
}

const agencyStore = new AgencyStore();
export default agencyStore;
