import "regenerator-runtime/runtime";
import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';
import '../node_modules/bootstrap/dist/js/bootstrap';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KW8WMQZ'
  }
  
  TagManager.initialize(tagManagerArgs)
  smoothscroll.polyfill();
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
