import React from 'react';
import { observer } from 'mobx-react';
import clientStore from '../../store/ClientStore';
import Helper from '../../../../helper/index';


const defaultFilterSettings = {
	startDate: 0,
	endDate: new Date(),
}

const STRINGS: {
	[key: string]: string
} = {
	all: 'Alle',
	high: 'Hoch',
	medium: 'Mittel',
	low: 'Tief'
}

type MultiFilterBarProps = {
	dashboardFlag?: boolean;
	currentView?: string;
};

@observer
class MultiFilterBar extends React.Component<MultiFilterBarProps> {

	componentDidUpdate() {
		const { startDate, endDate, selectedPolitSources, selectedMediaSources } = clientStore;

		if (
			startDate !== defaultFilterSettings.startDate ||
			Helper.normalizeDate(endDate) !== Helper.normalizeDate(defaultFilterSettings.endDate.getTime()) ||
			selectedPolitSources.length !== clientStore.politSources.length ||
			selectedMediaSources.length !== clientStore.mediaSources.filter((source) => source.disabled === false).length
		) {
			clientStore.isActiveMultiFilter = true;
		} else clientStore.isActiveMultiFilter = false;
	}

	onChange = (e: any) => {
		clientStore.searchInputValue = e.target.value;
	}

	onToggle = () => {
		clientStore.isOpenMultiFilter = !clientStore.isOpenMultiFilter
	}

	onClick = (priorityFilter: any) => {
		let newPriorities = []
		if (priorityFilter) {
			if (clientStore.priorityFilter.includes(priorityFilter)) {
				newPriorities = clientStore.priorityFilter.filter((e: string | null) => e !== priorityFilter)
			} else {
				newPriorities = [...clientStore.priorityFilter, priorityFilter]
			}
		}
		clientStore.priorityFilter = newPriorities;
	}

	render() {
		const priorityFilterData = [null, 'high', 'medium', 'low'];
		const displayedFilter = clientStore.priorityFilter.length > 0 ? <strong>Priorität</strong> : 'Priorität'
		return (
			<div className="control-board">
				<div>
					{!this.props.dashboardFlag && <div className="dropdown">
						<button className="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
							<span>
								{clientStore.priorityFilter !== null
									? <div className={`${clientStore.priorityFilter}`}></div>
									: <i className="fas fa-th-list"></i>
								}
							</span>
							<span className='field-title'>{displayedFilter}</span>
							<span>({clientStore.pagination.totalCount})</span>
						</button>
						<ul className="dropdown-menu">
							<li onClick={() => this.onClick(priorityFilterData[0])}>
								<div>
									<i className="fas fa-th-list"></i>
									<span>Alle</span>
								</div>
							</li>
							<li onClick={() => this.onClick(priorityFilterData[1])}>
								<div>
									<span className="high"></span>
									<span className={`${clientStore.priorityFilter.includes('high')&& 'bold'}`} >Hoch</span>
								</div>
							</li>
							<li onClick={() => this.onClick(priorityFilterData[2])}>
								<div>
									<span className="medium"></span>
									<span className={`${clientStore.priorityFilter.includes('medium')&& 'bold'}`}>Mittel</span>
								</div>
							</li>
							<li onClick={() => this.onClick(priorityFilterData[3])}>
								<div>
									<span className="low"></span>
									<span className={`${clientStore.priorityFilter.includes('low')&& 'bold'}`}>Tief</span>
								</div>
							</li>
						</ul>
					</div>}
				</div>
				<div className="filter-board">
					<input className="form-control" type="text" placeholder="Suche..." aria-label="Search" onChange={this.onChange} disabled={clientStore.isOpenMultiFilter && true} />
					<i className={`fa fa-filter ${clientStore.isActiveMultiFilter ? 'active' : ''}`} onClick={this.onToggle}></i>
					<i className="fas fa-ellipsis-v" style={{ display: "none" }}></i>
				</div>
			</div>
		)
	}
}
export default MultiFilterBar;