import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
	agencyStore,
	agencyIssueFormStore,
	agencyClientFormStore,
} from '../store';
import Button from '../../../shared/Button';
import AccordionIssue from './AccordionIssue';
import IssueOverlay from '../overlays/IssueOverlay';
import ClientOverlay from '../overlays/ClientOverlay';
import UserOverlay from '../overlays/UserOverlay';
import DocInfoForm from '../overlays/DocInfoOverlay';
import CatalogServices from '../../../services/CatalogServices';
import userStore from '../../../stores/UserStore';
import ClientStore from '../../Client/store/ClientStore';

type PathParamsType = {
	id: string;
};

type PropsType = RouteComponentProps<PathParamsType> & {};

@observer
class ClientInfo extends Component<PropsType> {
	state = {
		display: 'issues'
	}

	componentDidMount() {
		agencyStore.getCurrentClient(this.props.match.params.id);
		agencyStore.getClientIssues(this.props.match.params.id);
		agencyStore.getClientUsers(this.props.match.params.id);
		agencyStore.setActiveTab(this.props.match.params.id);
	}

	componentDidUpdate(prevProps: PropsType) {
		const { id } = this.props.match.params;
		const { id: prevId } = prevProps.match.params;
		if (prevId !== id) {
			agencyStore.setActiveTab(id);
		}
	}

	openEditClientOverlay = () => {
		const { currentClient } = agencyStore;
		agencyClientFormStore.setClientInfo({ ...currentClient });
		agencyStore.showOverlay(
			'overlay',
			<ClientOverlay id="overlay" isEditOverlay={true} />
		);
	};


	openUserOverlay = () => {
		agencyStore.showOverlay(
			'overlay',
			<UserOverlay id="overlay" isEditOverlay={false} />
		);
	};


	openCreateIssueOverlay = () => {
		agencyStore.showOverlay(
			'overlay',
			<IssueOverlay
				id="overlay"
				isEditOverlay={false}
				issue={agencyIssueFormStore.issue}
			/>
		);
	};

	openFileUploadOverlay = () => {
		agencyStore.showOverlay('overlay', <DocInfoForm id="overlay" />);
	};

	restoreUser = async () => {
		const { _id } = agencyStore.currentClient;
		await CatalogServices.putEditClient(_id, { isDeleted: false });
		agencyStore.currentClient.isDeleted = false;
	}

	removeUser = async () => {
		const { _id } = agencyStore.currentClient;
		await CatalogServices.deleteUser(_id);
		agencyStore.currentClient.isDeleted = true;
	}

	loginAs = async () => {
		const { _id, agency } = agencyStore.currentClient;

		const { data } = await CatalogServices.loginAs({
			_id,
			agency: agency || 'firstagency',
		});
		console.log()
		userStore.user = {...data.user, role: "agency-admin"};
		userStore.token = data.token;

		const newLocation = window.location.href.replace(window.location.pathname, '')

		window.location.replace(newLocation)
		window.history.pushState({}, '/');
		ClientStore.setFilteredAffairs(true);
	}

	render() {
		const { username, email, isDeleted } = agencyStore.currentClient;
		return (
			<section className='cleintInfoContainer'>
				<div className="cleintInfo">
					<div className="agency_cleintInfo__info">
						<h4>Kundeninfo</h4>
						<div>
							<p>Kundenname:</p>
							<p>{username}</p>
						</div>
						<Button
							text="Log in"
							onClick={this.loginAs}
							className="btn btn-outline-danger mr-3 log-in_btn"
						/>
					</div>
					<div className="agency_cleintInfo__btn-bar">
						<Button
							text="Bearbeiten"
							onClick={this.openEditClientOverlay}
							className="btn btn-outline-danger info-buttons"
						/>
						<Button
							text="Dokument anlegen" 
							onClick={this.openFileUploadOverlay}
							className="btn btn-outline-primary info-buttons"
						/>

					</div>
				</div>
				<div className="actionDisplay">
					<div className='NavigationWrapper'>
						<div
							className={`navigationOption ${this.state.display === 'issues' && 'active'}`}
							onClick={() => this.setState({
								display: 'issues'
							})}
						>Issues</div>
						<div
							className={`navigationOption ${this.state.display === 'users' && 'active'}`}
							onClick={() => this.setState({
								display: 'users'
							})}
						>Benutzer</div>
					</div>
					{this.state.display === 'issues'
						? <div className="content">
							<Button
								text="Issue anlegen"
								onClick={this.openCreateIssueOverlay}
								className="btn btn-outline-success mr-3"
							/>
							<br />
							<br />
							<AccordionIssue display={this.state.display} />
						</div>
						: <div className="content">
							<Button
								text="User anlegen"
								onClick={this.openUserOverlay}
								className="btn btn-outline-success mr-3"
							/>
							<br />
							<br />
							<AccordionIssue display={this.state.display} />
						</div>
					}
				</div>
			</section>
		);
	}
}

export default withRouter(ClientInfo);
